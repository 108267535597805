import {mapRecordToItems} from '@/helpers/mapRecordToItems.helper';

export enum PaycheckState {
    PENDING,
    DECLINED,
    CONFIRMED,
    PAYMENT_PREPARED,
    PAID,
}

export namespace PaycheckState {
    type Data = {
        text: string;
        color: string;
        icon: string;
        hint: string;
    };

    export const Record: Record<PaycheckState, Data> = {
        [PaycheckState.PENDING]: {
            text: 'Prüfung ausstehend',
            color: 'warning',
            icon: 'mdi-clock-outline',
            hint: 'Die Abrechnung enthält Sonderhonorare, die aktuell noch geprüft werden',
        },
        [PaycheckState.DECLINED]: {
            text: 'Abgelehnt',
            color: 'error',
            icon: 'mdi-close',
            hint: 'Die Abrechnung wurde in ihrer aktuellen Form abgelehnt. Bitte überprüfe deine Angaben',
        },
        [PaycheckState.CONFIRMED]: {
            text: 'Freigegeben',
            color: 'info',
            icon: 'mdi-check',
            hint: 'Die Abrechnung wurde erfolgreich geprüft und freigegeben. Sie wird in Kürze ausgezahlt',
        },
        [PaycheckState.PAYMENT_PREPARED]: {
            text: 'Zahlung vorbereitet',
            color: 'info',
            icon: 'mdi-check',
            hint: 'Die Abrechnung wurde zur Auszahlung vorbereitet',
        },
        [PaycheckState.PAID]: {
            text: 'Bezahlt',
            color: 'success',
            icon: 'mdi-check-all',
            hint: 'Die Abrechnung wurde erfolgreich ausgezahlt',
        },
    };

    export const Items = mapRecordToItems(Record);
}
