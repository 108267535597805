import {mapRecordToItems} from '@/helpers/mapRecordToItems.helper';

export enum EmployeeContractTemplateFields {
    NAME = 'name',
    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',

    SALARY_PER_SUBMISSION = 'salaryPerSubmission',
    SALARY_PER_ADVERTISING_EVENT = 'salaryPerAdvertisingEvent',

    SALARY_BASIS = 'salaryBasis',
    SALARY_PER_UNIT = 'salaryPerUnit',
    ADDITIONAL_SALARY_PER_DAY = 'additionalSalaryPerDay',
    ADDITIONAL_SALARY_PER_DAY_DECLARATION = 'additionalSalaryPerDayDeclaration',
    PAID_VACATION = 'paidVacation',
    PAID_BREAKS = 'paidBreaks',
}

export namespace EmployeeContractTemplateFields {
    type Data = {
        text: string;
        readOnly?: true;
        updateOnly?: true;
    }

    export const Record: Record<EmployeeContractTemplateFields, Data> = {
        [EmployeeContractTemplateFields.NAME]: {
            text: 'Name',
        },
        [EmployeeContractTemplateFields.CREATED_AT]: {
            text: 'Erzeugungsdatum',
            readOnly: true,
        },
        [EmployeeContractTemplateFields.UPDATED_AT]: {
            text: 'Letztes Aktualisierungsdatum',
            readOnly: true,
        },
        [EmployeeContractTemplateFields.SALARY_PER_SUBMISSION]: {
            text: 'Vergütung je Neukunde',
        },
        [EmployeeContractTemplateFields.SALARY_PER_ADVERTISING_EVENT]: {
            text: 'Vergütung je Werbeaktion',
        },
        [EmployeeContractTemplateFields.SALARY_BASIS]: {
            text: 'Basis der Vergütung',
        },
        [EmployeeContractTemplateFields.SALARY_PER_UNIT]: {
            text: 'Vergütung pro Einheit (z.B. Stunde)',
        },
        [EmployeeContractTemplateFields.ADDITIONAL_SALARY_PER_DAY]: {
            text: 'Zusatzvergütung pro Tag',
        },
        [EmployeeContractTemplateFields.ADDITIONAL_SALARY_PER_DAY_DECLARATION]: {
            text: 'Erklärung zur Zusatzvergütung pro Tag',
        },
        [EmployeeContractTemplateFields.PAID_VACATION]: {
            text: 'Hat Anspruch auf bezahlten Urlaub',
        },
        [EmployeeContractTemplateFields.PAID_BREAKS]: {
            text: 'Hat Anspruch auf bezahlte Pausen',
        },
    };

    export const Items = mapRecordToItems(Record);
}
