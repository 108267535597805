import {LeanDocument, Types} from 'mongoose';
import {API} from './api.class';
import {FindByIdOptionsRequestDto} from '@/classes/dto/_common/request/FindByIdOptions.request.dto';
import {FindApiConfigurationRequestDto} from '@/classes/dto/api-handler/request/FindApiConfiguration.request.dto';
import {ApiConfiguration, ApiConfigurationDocument} from '@/documentTypes/api-handler/ApiConfiguration';
import {
    CreateFastBillApiConfigurationRequestDto,
} from '@/classes/dto/api-handler/request/fast-bill/CreateFastBillApiConfiguration.request.dto';
import {
    UpdateFastBillApiConfigurationRequestDto,
} from '@/classes/dto/api-handler/request/fast-bill/UpdateFastBillApiConfiguration.request.dto';
import {
    CreateJoinApiConfigurationRequestDto,
} from '@/classes/dto/api-handler/request/join/CreateJoinApiConfiguration.request.dto';
import {
    UpdateJoinApiConfigurationRequestDto,
} from '@/classes/dto/api-handler/request/join/UpdateJoinApiConfiguration.request.dto';
import {
    CreateWordPressApiConfigurationRequestDto,
} from '@/classes/dto/api-handler/request/word-press/CreateWordPressApiConfiguration.request.dto';
import {
    UpdateWordPressApiConfigurationRequestDto,
} from '@/classes/dto/api-handler/request/word-press/UpdateWordPressApiConfiguration.request.dto';
import {GetAllCategoriesJoinRequestDto} from '@/classes/dto/api-handler/request/join/api/categories';
import {GetAllEmploymentTypesJoinRequestDto} from '@/classes/dto/api-handler/request/join/api/employmentTypes';
import {GetAllSeniorityLevelsJoinRequestDto} from '@/classes/dto/api-handler/request/join/api/seniorityLevels';
import {
    GetWordPressCategoryLikeRequestDto,
    GetWordPressCategoryRequestDto,
} from '@/classes/dto/api-handler/request/word-press/api/categories';
import {GetWordPressCategoryResponseDto} from '@/classes/dto/api-handler/response/word-press/api';
import {
    CreateInternetmarkeApiConfigurationRequestDto,
} from '@/classes/dto/api-handler/request/internetmarke/CreateInternetmarkeApiConfiguration.request.dto';
import {
    UpdateInternetmarkeApiConfigurationRequestDto,
} from '@/classes/dto/api-handler/request/internetmarke/UpdateInternetmarkeApiConfiguration.request.dto';
import {
    CreatePlacetelApiConfigurationRequestDto,
} from '@/classes/dto/api-handler/request/placetel/CreatePlacetelApiConfiguration.request.dto';
import {
    UpdatePlacetelApiConfigurationRequestDto,
} from '@/classes/dto/api-handler/request/placetel/UpdatePlacetelApiConfiguration.request.dto';
import {
    GetSipConfigByMailPlacetelResponseDto,
} from '@/classes/dto/api-handler/response/placetel/api/GetSipConfigByMail.placetel.response.dto';
import {
    GetSipConfigByMailPlacetelRequestDto,
} from '@/classes/dto/api-handler/request/placetel/api/GetSipConfigByMail.placetel.request.dto';
import {
    CreateWhatsAppApiConfigurationRequestDto,
} from '@/classes/dto/api-handler/request/whats-app/CreateWhatsAppApiConfiguration.request.dto';
import {
    UpdateWhatsAppApiConfigurationRequestDto,
} from '@/classes/dto/api-handler/request/whats-app/UpdateWhatsAppApiConfiguration.request.dto';
import {
    SendWhatsAppTextMessageRequestDto,
} from '@/classes/dto/api-handler/request/whats-app/api/messages/SendWhatsAppTextMessage.request.dto';
import {
    SendWhatsAppMessageResponseDto,
} from '@/classes/dto/api-handler/response/whats-app/api/SendWhatsAppMessage.response.dto';
import {
    GetWhatsAppMessagingHealthStatusResponseDto,
} from '@/classes/dto/api-handler/response/whats-app/api/GetWhatsAppMessagingHealthStatus.response.dto';
import {WhatsAppApiConfigurationDocument} from '@/documentTypes/api-handler/WhatsAppApiConfiguration';
import {
    GetWhatsAppMessageTemplatesRequestDto,
} from '@/classes/dto/api-handler/request/whats-app/api/templates/GetWhatsAppMessageTemplates.request.dto';
import {
    GetWhatsAppMessageTemplatesResponseDto,
} from '@/classes/dto/api-handler/response/whats-app/api/GetWhatsAppMessageTemplates.response.dto';
import {
    SendWhatsAppTemplateMessageRequestDto,
} from '@/classes/dto/api-handler/request/whats-app/api/messages/SendWhatsAppTemplateMessage.request.dto';
import {
    CreateMailApiConfigurationRequestDto,
} from '@/classes/dto/api-handler/request/mail/CreateMailApiConfiguration.request.dto';
import {
    UpdateMailApiConfigurationRequestDto,
} from '@/classes/dto/api-handler/request/mail/UpdateMailApiConfiguration.request.dto';
import {
    FindMailApiConfigurationRequestDto,
} from '@/classes/dto/api-handler/request/mail/FindMailApiConfiguration.request.dto';
import {MailApiConfigurationDocument} from '@/documentTypes/api-handler/MailApiConfiguration';
import {ParsedMail} from 'mailparser';
import {GetBoxesMailResponseDto} from '@/classes/dto/api-handler/response/mail/api/GetBoxes.mail.response.dto';
import {GetBoxesMailRequestDto} from '@/classes/dto/api-handler/request/mail/api/GetBoxes.mail.request.dto';
import {
    GetImapAccessDetailsMailRequestDto,
} from '@/classes/dto/api-handler/request/mail/api/GetImapAccessDetails.mail.request.dto';
import {
    GetImapAccessDetailsMailResponseDto,
} from '@/classes/dto/api-handler/response/mail/api/GetImapAccessDetails.mail.response.dto';
import {
    GetEmailAttachmentMailRequestDto,
} from '@/classes/dto/api-handler/request/mail/api/GetEmailAttachment.mail.request.dto';
import {
    GetWhatsAppMediaRequestDto,
} from '@/classes/dto/api-handler/request/whats-app/api/messages/GetWhatsAppMedia.request.dto';
import {
    ChargeInternetmarkeWalletResponseDto,
    CreateInternetmarkeStampsResponseDto,
    GetInternetmarkeCatalogResponseDto,
    GetInternetmarkeWalletBalanceResponseDto,
} from '@/classes/dto/api-handler/response/internetmarke/api';
import {
    ChargeInternetmarkeWalletRequestDto,
    CreateInternetmarkeStampsRequestDto,
    GetInternetmarkeCatalogRequestDto,
} from '@/classes/dto/api-handler/request/internetmarke/api';
import {
    CreateInternetmarkeManualStampsRequestDto,
} from '@/classes/dto/api-handler/request/internetmarke/api/CreateInternetmarkeManualStamps.request.dto';
import {
    CreateInternetmarkeManualStampsResponseDto,
} from '@/classes/dto/api-handler/response/internetmarke/api/CreateInternetmarkeManualStamps.response.dto';
import {GetApiQueueLimitsResponseDto} from '@/classes/dto/api-handler/response/GetApiQueueLimits.response.dto';

/**
 * This class is used for communicating with the api-handler microservice.
 * @class ApiHandlerApi
 * @author Joshua Seipel
 */
export class ApiHandlerApi extends API {
    static service = 'api-handler';
    static mail = {
        create: async (data: CreateMailApiConfigurationRequestDto): Promise<Types.ObjectId> => {
            return this.post(`mail`, data);
        },
        update: async (id: Types.ObjectId, data: UpdateMailApiConfigurationRequestDto) => {
            return this.put(`mail/${id}`, data);
        },
        find: async (data: FindMailApiConfigurationRequestDto): Promise<{
            mailApiConfigurations: LeanDocument<MailApiConfigurationDocument>[];
            maxPage: number
        }> => {
            return this.get(`mail/find`, data);
        },
        api: {
            getMails: async (id: Types.ObjectId): Promise<ParsedMail[]> => {
                return this.get(`mail-api/mails/${id}`);
            },
            getBoxes: async (id: Types.ObjectId, query?: GetBoxesMailRequestDto): Promise<GetBoxesMailResponseDto> => {
                return this.get(`mail-api/boxes/${id}`, query);
            },
            getImapAccessDetails: async (data: GetImapAccessDetailsMailRequestDto): Promise<GetImapAccessDetailsMailResponseDto[]> => {
                return this.get(`mail-api/imap-access-details`, data);
            },
            getImapAccessDetailsById: async (id: Types.ObjectId): Promise<GetImapAccessDetailsMailResponseDto> => {
                return this.get(`mail-api/imap-access-details/${id}`);
            },
            getAttachment: async (data: GetEmailAttachmentMailRequestDto): Promise<Blob> => {
                return this.get(`mail-api/attachment/${data.username}/${data.box}/${data.uid}/${data.filename}`, {}, 'blob');
            },
        },
    }
    static whatsApp = {
        create: async (data: CreateWhatsAppApiConfigurationRequestDto) => {
            return this.post(`whats-app`, data);
        },
        update: async (id: Types.ObjectId, data: UpdateWhatsAppApiConfigurationRequestDto) => {
            return this.put(`whats-app/${id}`, data);
        },
        find: async (data: FindApiConfigurationRequestDto): Promise<{
            whatsAppApiConfigurations: LeanDocument<WhatsAppApiConfigurationDocument>[];
            maxPage: number
        }> => {
            return this.get(`whats-app/find`, data);
        },
        api: {
            healthStatus: async (id: Types.ObjectId): Promise<GetWhatsAppMessagingHealthStatusResponseDto> => {
                return this.get(`whats-app-api/health-status/${id}`);
            },
            sendTextMessage: async (id: Types.ObjectId, data: SendWhatsAppTextMessageRequestDto): Promise<SendWhatsAppMessageResponseDto> => {
                return this.post(`whats-app-api/send-message/text/${id}`, data);
            },
            sendTemplateMessage: async (id: Types.ObjectId, data: SendWhatsAppTemplateMessageRequestDto): Promise<SendWhatsAppMessageResponseDto> => {
                return this.post(`whats-app-api/send-message/template/${id}`, data);
            },
            getMessageTemplates: async (id: Types.ObjectId, data: GetWhatsAppMessageTemplatesRequestDto): Promise<GetWhatsAppMessageTemplatesResponseDto> => {
                return this.get(`whats-app-api/message-templates/${id}`, data);
            },
            getMedia: async (data: GetWhatsAppMediaRequestDto): Promise<Blob> => {
                return this.get(`whats-app-api/media/${data.phoneNumberId}/${data.mediaId}`, {}, 'blob');
            },
        },
    }
    static internetmarke = {
        create: async (data: CreateInternetmarkeApiConfigurationRequestDto) => {
            return this.post(`internetmarke`, data);
        },
        update: async (id: Types.ObjectId, data: UpdateInternetmarkeApiConfigurationRequestDto) => {
            return this.put(`internetmarke/${id}`, data);
        },
        api: {
            canAccess: async (): Promise<boolean> => {
                return this.get(`internetmarke-api/can-access`);
            },
            getBalance: async (): Promise<GetInternetmarkeWalletBalanceResponseDto> => {
                return this.get(`internetmarke-api/balance`);
            },
            chargeWallet: async (data: ChargeInternetmarkeWalletRequestDto): Promise<ChargeInternetmarkeWalletResponseDto> => {
                return this.put(`internetmarke-api/charge-wallet`, data);
            },
            getCatalog: async (data: GetInternetmarkeCatalogRequestDto): Promise<GetInternetmarkeCatalogResponseDto> => {
                return this.get(`internetmarke-api/catalog`, data);
            },
            createStamps: async (data: CreateInternetmarkeStampsRequestDto): Promise<CreateInternetmarkeStampsResponseDto> => {
                return this.post(`internetmarke-api/create-stamps`, data);
            },
            createStampsManual: async (data: CreateInternetmarkeManualStampsRequestDto): Promise<CreateInternetmarkeManualStampsResponseDto> => {
                return this.post(`internetmarke-api/create-stamps-manual`, data);
            },
        },
    }
    static fastBill = {
        create: async (data: CreateFastBillApiConfigurationRequestDto) => {
            return this.post(`fast-bill`, data);
        },
        update: async (id: Types.ObjectId, data: UpdateFastBillApiConfigurationRequestDto) => {
            return this.put(`fast-bill/${id}`, data);
        },
        api: {
          getLimits: async (): Promise<GetApiQueueLimitsResponseDto> => {
            return this.get(`fast-bill-api/queue-limits`);
          },
        },
    }
    static placetel = {
        create: async (data: CreatePlacetelApiConfigurationRequestDto) => {
            return this.post('placetel', data);
        },
        update: async (id: Types.ObjectId, data: UpdatePlacetelApiConfigurationRequestDto) => {
            return this.put(`placetel/${id}`, data);
        },
        api: {
            getSipConfigByMail: async (data: GetSipConfigByMailPlacetelRequestDto): Promise<GetSipConfigByMailPlacetelResponseDto> => {
                return this.get(`placetel-api/sip-config-by-mail/${data.email}`)
            },
        },
    }
    static join = {
        create: async (data: CreateJoinApiConfigurationRequestDto) => {
            return this.post(`join`, data);
        },
        update: async (id: Types.ObjectId, data: UpdateJoinApiConfigurationRequestDto) => {
            return this.put(`join/${id}`, data);
        },
        api: {
            getCategories: async (data: GetAllCategoriesJoinRequestDto) => {
                return this.get(`join-api/categories`, data);
            },
            getEmploymentTypes: async (data: GetAllEmploymentTypesJoinRequestDto) => {
                return this.get(`join-api/employment-types`, data);
            },
            getSeniorityLevels: async (data: GetAllSeniorityLevelsJoinRequestDto) => {
                return this.get(`join-api/seniority-levels`, data);
            },
            getJobCount: async () => {
                return this.get(`join-api/job-count`);
            },
            getApplication: async (id: number) => {
                return this.get(`join-api/applications/${id}`);
            },
        },
    }
    static wordPress = {
        create: async (data: CreateWordPressApiConfigurationRequestDto) => {
            return this.post(`word-press`, data);
        },
        update: async (id: Types.ObjectId, data: UpdateWordPressApiConfigurationRequestDto) => {
            return this.put(`word-press/${id}`, data);
        },
        api: {
            categories: {
                get: async (data: GetWordPressCategoryRequestDto): Promise<GetWordPressCategoryResponseDto[]> => {
                    return this.get(`word-press-api/categories`, data);
                },
            },
            categoryLikes: {
                get: async (data: GetWordPressCategoryLikeRequestDto): Promise<GetWordPressCategoryResponseDto[]> => {
                    return this.get(`word-press-api/category-likes`, data);
                },
            },
        },
    }

    static async find<T = ApiConfigurationDocument>(c: FindApiConfigurationRequestDto): Promise<{
        apiConfigurations: LeanDocument<T>[];
        maxPage: number
    }> {
        return this.get(``, c);
    }

    static async findById(id: Types.ObjectId, options?: FindByIdOptionsRequestDto): Promise<LeanDocument<ApiConfiguration>> {
        return this.get(`${id}`, options);
    }
}
