<template>
  <div v-if="entity">
    <v-divider/>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-file-document-edit</v-icon>
      </v-list-item-icon>
      <v-list-item-content v-if="entity.contract && !isObjectId(entity.contract) && entity.contract.name">
        <v-list-item-title>
          {{entity.contract.name}} (veraltet)
        </v-list-item-title>
        <v-list-item-subtitle>
          Arbeitsvertrag - <span v-can:read-contracts="{ id: entity.contract._id, keys: ['salaryPerUnit', 'salaryBasis'] }">
          {{toCurrency(entity.contract.salaryPerUnit)}} {{SalaryBasisVue.find((el) => el.value === entity.contract?.salaryBasis)?.text}}
        </span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-content v-else-if="entity.contract && isObjectId(entity.contract)">
        <v-list-item-title class="grey--text">
          Arbeitsvertrag unbekannt
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-content v-else>
        <v-list-item-title class="grey--text">
          Kein Arbeitsvertrag hinterlegt
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action v-if="entity && entity.contract && entity.contract._id" v-can:read-contracts="{id: entity.contract._id, keys: ['name']}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="showContractCardDialog = true">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>Ansehen</span>
        </v-tooltip>
      </v-list-item-action>
    </v-list-item>

    <!-- DIALOGS -->
    <contract-card-dialog v-if="entity.contract && entity.contract.name" v-model="showContractCardDialog" :contract-id="entity.contract._id" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Contract } from '@/interfaces/contract.interface';
import { isObjectId } from '@/helpers/isObjectId.helper';
import { toCurrencyString } from '@/helpers/toCurrencyString.helper';
import {SalaryBasis} from '@/enums/SalaryBasis.enum';
import ContractCardDialog from '@/components/contracts/contractCardDialog.vue';

interface CanHaveContract {
  contract?: Contract;
}

export default Vue.extend({
  components: {
    ContractCardDialog,
  },
  props: {
    entity: {
      type: Object as () => CanHaveContract,
      required: true,
    },
  },
  data: () => ({
    showContractCardDialog: false,
  }),
  computed: {
    isObjectId: () => isObjectId,
    toCurrency: () => toCurrencyString,
    SalaryBasisVue: () => SalaryBasis.Items,
  },
})
</script>
