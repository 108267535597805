<template>
  <v-dialog :value="value" max-width="800" :persistent="loading" @input="reset"
            :fullscreen="$vuetify.breakpoint.smAndDown">
    <v-card>
      <v-card-title>
        Portokasse aufladen
        <v-spacer/>
        <v-btn icon @click="reset">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="initializing">
        <v-skeleton-loader type="article, actions"/>
      </v-card-text>
      <v-card-text v-else>
        <v-form ref="form" v-model="valid" lazy-validation @submit="chargeWallet">
          <v-alert type="info" v-if="typeof balance !== 'undefined' && !initializing">
            Der aktuelle Kontostand beträgt <strong>{{ toCurrencyString(balance / 100) }}</strong>.
          </v-alert>
          <v-alert type="error" v-else>
            Der aktuelle Kontostand konnte nicht abgerufen werden. Überprüfe, ob deine Berechtigungen ausreichen.
          </v-alert>
          <v-row>
            <v-col>
              <money-input color="info" dense outlined rounded label="Betrag eingeben" :disabled="!hasAccess" v-model="amount" :rules="[RuleFactory.required(), RuleFactory.minValue(1), RuleFactory.maxValue(200)]" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="reset">
          Abbrechen
        </v-btn>
        <v-spacer/>
        <v-btn :loading="loading" color="info" @click="chargeWallet" :disabled="!valid || !hasAccess">
          Zahlungspflichtig bestellen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">


import Vue from "vue";
import {RuleFactory} from "@/helpers/ruleFactory.helper";
import {Form} from "@/interfaces/Form.interface";
import {toCurrencyString} from "@/helpers/toCurrencyString.helper";
import {ApiHandlerApi} from "@/classes/api/api-handler.api";
import {CustomAccessKeys} from "@/classes/clientOnly/permissionTreeResources/enums/CustomAccessKeys";
import MoneyInput from "@/components/common/moneyInput.vue";

export default Vue.extend({
  components: {MoneyInput},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    valid: false,
    balance: undefined as undefined | number,
    amount: 10,
    loading: false,
    initializing: false,
    hasAccess: false as boolean,
  }),
  computed: {
    RuleFactory: () => RuleFactory,
    toCurrencyString: () => toCurrencyString,
  },
  methods: {
    reset() {
      const form = this.$refs.form as Form;
      if (form) {
        form.resetValidation();
      }
      this.balance = undefined;
      this.amount = 10;
      this.$emit('input', false);
    },
    async chargeWallet() {
      const form = this.$refs.form as Form;
      if (form && form.validate()) {
        this.loading = true;
        try {
          const resp = await ApiHandlerApi.internetmarke.api.chargeWallet({
            amount: this.amount * 100,
          });
          if (resp.walletBalance) {
            this.balance = resp.walletBalance;
          }
          this.$$showSnackbar(`Die Portokasse wurde erfolgreicht aufgeladen. ${typeof resp.walletBalance === 'number' ?
              `Der neue Kontostand beträgt ${this.toCurrencyString(resp.walletBalance / 100)}` : ''
          }`, 'success');
          this.$emit('update:walletBalance');
          this.reset();
        } catch (e) {
          this.$$showSnackbar('Fehler beim Aufladen des Kontos', 'error', e);
        } finally {
          this.loading = false;
        }
      }
    },
    async checkAccess() {
      const hasReadAccess = await ApiHandlerApi.internetmarke.api.canAccess();
      const hasChargeAccess = this.$$crudAccessManager.getCustom(CustomAccessKeys.CHARGE_PORTOKASSE);
      this.hasAccess = hasReadAccess && !!hasChargeAccess;
    },
    async getBalance() {
      this.initializing = true;
      try {
        const resp = await ApiHandlerApi.internetmarke.api.getBalance();
        this.balance = resp.walletBalance;
      } catch (e) {
        this.$$showSnackbar('Fehler beim Abrufen des Kontostands', 'error', e);
      } finally {
        this.initializing = false;
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v: boolean) {
        if (v) {
          this.checkAccess();
          this.getBalance();
        }
      },
    },
  },
});
</script>
