type ExceptionOrigin = {
    /** The name of the function that threw the exception */
    fn: string;
    /** The file where the function is located */
    file?: string;
}

type Options = {
    modifyOrigin?: {
        prepend?: ExceptionOrigin[] | string;
        /** The name of the service that produced the origin to prepend */
        service?: string;
    },
}

export abstract class VanillaException {
    protected readonly _n: string;
    public readonly origin: ExceptionOrigin[] | undefined;
    /** The stack trace of the exception - defined on Server */
    protected readonly stack: string | undefined;

    protected constructor(namespace: string, public readonly name: string, public readonly message: string, protected readonly statusCode: number, options?: Options) {
        this._n = namespace;
        if (this.stack) {
            this.origin = [];

            const prependOrigin = options?.modifyOrigin?.prepend;
            if (prependOrigin) {
                if (typeof prependOrigin === 'string') {
                    this.origin.push(...this.stackToOrigin(prependOrigin));
                } else {
                    this.origin.push(...prependOrigin);
                }
            }
            const prependService = options?.modifyOrigin?.service;
            if (prependService) {
                this.origin.push({fn: `Service: ${prependService}`});
            }

            this.origin.push(...this.stackToOrigin(this.stack));
        }
    }

    private stackToOrigin(stack: string): ExceptionOrigin[] {
        return stack.split('\n').slice(1).map((line) => {
            const clean = line.trim().replace(/^\s*at\s/, '');
            const [fn, file] = clean.split(' (');
            return {fn, file: file?.replace(')', '')};
        });
    }

    public getResponse(): object {
        return {
            ...this,
            status: undefined,
            _t: this.constructor.name,
        }
    }

    public get furtherInformationHTML(): string {
        return '';
    }

    public get namespace(): string {
        return this._n;
    }

    public get type(): string {
        return this.constructor.name;
    }
}
