import {CountryCode, parsePhoneNumberFromString} from 'libphonenumber-js';
import {isNaN} from 'mathjs';

export type Rule = (value: any) => string | true;

function isEmpty(value: any): boolean {
    return typeof value === 'undefined' || value === null || value === '';
}

const required = (msg?: string): Rule => {
    return (v: any) => {
        if (Array.isArray(v)) {
            return v.length > 0 || msg || 'Dieses Feld ist erforderlich';
        }
        return !isEmpty(v) || msg || 'Dieses Feld ist erforderlich';
    };
};

const maxLength = (length: number, msg?: string): Rule => {
    return (v: string) => {
        return (!v || v.length <= length)
            || msg || `Die maximale Zeichenlänge beträgt ${length} Zeichen`;
    };
};

const maxLengthEach = (length: number, msg?: string): Rule => {
    return (v: string[]) => {
        if (!v) {
            return true;
        }
        for (const el of v) {
            const result = maxLength(length, msg)(el);
            if (result !== true) {
                return result;
            }
        }
        return true;
    };
};

const minLength = (length: number, msg?: string): Rule => {
    return (v: string) => {
        return (!v || v.length >= length)
            || msg || `Die minimale Zeichenlänge beträgt ${length} Zeichen`;
    };
};

const minLengthEach = (length: number, msg?: string): Rule => {
    return (v: string[]) => {
        if (!v) {
            return true;
        }
        for (const el of v) {
            const result = minLength(length, msg)(el);
            if (result !== true) {
                return result;
            }
        }
        return true;
    };
};

const maxValue = (max: number, msg?: string): Rule => {
    return (v: string | number) => {
        return (isEmpty(v) || Number(v) <= max)
            || msg || `Der Maximalwert für dieses Feld liegt bei ${max}`;
    };
};

const minValue = (min: number, msg?: string): Rule => {
    return (v: string | number) => {
        return (isEmpty(v) || Number(v) >= min)
            || msg || `Der Minimalwert für dieses Feld liegt bei ${min}`;
    };
};

const isInteger = (msg?: string): Rule => {
    return (v: number) => {
        const num = Number(v);
        const valid = !isNaN(num) && num - Math.round(num) === 0;
        return !v || valid || msg || 'Für dieses Feld sind nur Ganzzahlen zulässig';
    };
};

const isNumber = (msg?: string): Rule => {
    return (v: any) => {
        return isEmpty(v) || typeof v === 'number' && !isNaN(v) || msg || 'Für dieses Feld sind nur Zahlen zulässig';
    };
};

const isNumericString = (msg?: string): Rule => {
    return (v: any) => {
        const num = Number(v);
        return isEmpty(v) || !isNaN(num) || msg || 'Für dieses Feld sind nur Ziffern zulässig';
    };
};

const isValidMail = (msg?: string): Rule => {
    return (v: string) => {
        if (!v) {
            return true;
        }

        const values: any[] = [];

        if (Array.isArray(v)) {
            if (v.length === 0) {
                return true;
            }
            values.push(...v);
        } else {
            values.push(v);
        }

        for (const value of values) {
            // eslint-disable-next-line no-control-regex
            const rex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gmi;
            const result = rex.exec(value);
            if (!result || result[0].length !== value.length) {
                return msg || Array.isArray(v) ? `"${value}" keine gültige E-Mail-Adresse` : 'Das ist keine gültige E-Mail-Adresse';
            }
        }
        return true;
    };
};

const isValidPhoneNumber = (countryCode: CountryCode = 'DE', msg?: string): Rule => {
    return (v: string) => {
        if (!v) {
            return true;
        }

        const values: any[] = [];
        if (Array.isArray(v)) {
            if (v.length === 0) {
                return true;
            }
            values.push(...v);
        } else {
            values.push(v);
        }

        for (const value of values) {
            const phoneNumber = parsePhoneNumberFromString(value, countryCode);
            if (!phoneNumber || !phoneNumber.isValid()) {
                return msg || Array.isArray(v) ? `"${value}" keine gültige Telefonnummer` : 'Das ist keine gültige Telefonnummer';
            }
        }

        return true;
    };
};

const isValidGermanNumber = (msg?: string): Rule => {
    return (v: string) => {
        if (typeof v === 'undefined' || v === null) {
            return !v || 'Should not happen';
        }
        const convertedString = v.replace(/\./gm, '').replace(/,/gm, '.');
        return !v || !isNaN(Number(convertedString)) || msg || 'Die Zahleneingabe muss dem deutschen Format entsprechen';
    };
};

const isGermanPostalCode = (msg?: string): Rule => {
    return (v: string) => {
        if (typeof v === 'undefined' || v === null) {
            return !v || 'Should not happen';
        }
        if (v.length !== 5) {
            return msg || 'Eine deutsche Postleitzahl muss genau 5 Ziffern enthalten';
        }
        return !v || /^\d{5}$/g.test(v) || msg || 'Eine deutsche Postleitzahl muss genau 5 Ziffern enthalten';
    };
};

const isGermanPostalCodeEach = (msg?: string): Rule => {
    return (v: string[]) => {
        if (!v) {
            return true;
        }
        for (const el of v) {
            const result = isGermanPostalCode(msg)(el);
            if (result !== true) {
                return result;
            }
        }
        return true;
    };
};

const is = (value: any, msg: string): Rule => {
    return (v: any) => {
        return v === value || msg;
    };
}

const isNot = (value: any, msg: string): Rule => {
    return (v: any) => {
        return v !== value || msg;
    };
}

const minArrLength = (value: number, msg?: string): Rule => {
    return (v: any[]) => {
        return !v || (Array.isArray(v) && value <= v.length) || msg || `Bitte mindestens ${value === 1 ? `${value} Element` : `${value} Elemente`} auswählen`;
    }
}

const maxArrLength = (value: number, msg?: string): Rule => {
    return (v: any[]) => {
        return !v || (Array.isArray(v) && value >= v.length) || msg || `Bitte maximal ${value === 1 ? `${value} Element` : `${value} Elemente`} auswählen`;
    }
}

const isIn = (params: Array<string | number>, msg: string): Rule => {
    return (v: string) => {
        return !v || params.map((param) => param.toString()).includes(v) || msg;
    };
};

const isNotIn = (params: Array<string | number>, msg: string): Rule => {
    return (v: string) => {
        return !v || !params.map((param) => param.toString()).includes(v) || msg;
    };
};

const includes = (rex: RegExp, msg: string): Rule => {
    return (v: string) => {
        return !v || rex.test(v) || msg;
    };
};

const includesNot = (rex: RegExp, msg: string): Rule => {
    return (v: string) => {
        return !v || !rex.test(v) || msg;
    };
};

/** Bundle of different rules for vuetify */
export const RuleFactory = {
    required,
    maxLength,
    maxLengthEach,
    minLength,
    minLengthEach,
    maxValue,
    minValue,
    isInteger,
    isNumber,
    isNumericString,
    isValidMail,
    isValidPhoneNumber,
    isValidGermanNumber,
    isGermanPostalCodeEach,
    isGermanPostalCode,
    is,
    isNot,
    isIn,
    isNotIn,
    includes,
    includesNot,
    minArrLength,
    maxArrLength,
};

export enum RuleFactoryEnum {
    REQUIRED = 'required',
    MAX_LENGTH = 'maxLength',
    MIN_LENGTH = 'minLength',
    MAX_VALUE = 'maxValue',
    MIN_VALUE = 'minValue',
    IS_TRUE = 'isTrue',
    IS_INTEGER = 'isInteger',
    IS_NUMBER = 'isNumber',
    IS_NUMERIC_STRING = 'isNumericString',
    IS_VALID_MAIL = 'isValidMail',
    IS_VALID_PHONE_NUMBER = 'isValidPhoneNumber',
    IS_VALID_GERMAN_NUMBER = 'isValidGermanNumber',
    MIN_ARR_LENGTH = 'minArrLength',
    MAX_ARR_LENGTH = 'maxArrLength',
}
