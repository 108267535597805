import {mapRecordToItems} from '@/helpers/mapRecordToItems.helper';

export enum OneTimeSpecialFeeFields {
    EMPLOYEE = 'employee',
    TITLE = 'title',
    DESCRIPTION = 'description',
    AMOUNT = 'amount',
    SERVICE_DATE = 'serviceDate',
    IS_BILLED = 'isBilled',
    BILLING_PAYCHECK = 'billingPaycheck',
    WITHDRAW_ON_NEXT_PAYCHECK = 'withdrawOnNextPaycheck',
    IS_WITHDRAWN = 'isWithdrawn',
    PAYCHECK_OF_WITHDRAWAL = 'paycheckOfWithdrawal',
    GRANTED_BY = 'grantedBy',
    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',
}

export namespace OneTimeSpecialFeeFields {
    type Data = {
        text: string;
        readOnly?: true;
        updateOnly?: true;
    }

    export const Record: Record<OneTimeSpecialFeeFields, Data> = {
        [OneTimeSpecialFeeFields.EMPLOYEE]: {
            text: 'Mitarbeiter:in',
            readOnly: true,
        },
        [OneTimeSpecialFeeFields.TITLE]: {
            text: 'Titel',
        },
        [OneTimeSpecialFeeFields.DESCRIPTION]: {
            text: 'Beschreibung',
        },
        [OneTimeSpecialFeeFields.AMOUNT]: {
            text: 'Betrag',
        },
        [OneTimeSpecialFeeFields.SERVICE_DATE]: {
            text: 'Leistungsdatum',
        },
        [OneTimeSpecialFeeFields.IS_BILLED]: {
            text: 'Verrechnet',
            readOnly: true,
        },
        [OneTimeSpecialFeeFields.BILLING_PAYCHECK]: {
            text: 'Lohnabrechnung der Verrechnung',
            readOnly: true,
        },
        [OneTimeSpecialFeeFields.WITHDRAW_ON_NEXT_PAYCHECK]: {
            text: 'Bei nächster Lohnabrechnung abziehen',
        },
        [OneTimeSpecialFeeFields.IS_WITHDRAWN]: {
            text: 'Abgezogen',
            readOnly: true,
        },
        [OneTimeSpecialFeeFields.PAYCHECK_OF_WITHDRAWAL]: {
            text: 'Lohnabrechnung des Abzugs',
            readOnly: true,
        },
        [OneTimeSpecialFeeFields.GRANTED_BY]: {
            text: 'Gewährt von',
            readOnly: true,
        },
        [OneTimeSpecialFeeFields.CREATED_AT]: {
            text: 'Erstellt am',
            readOnly: true,
        },
        [OneTimeSpecialFeeFields.UPDATED_AT]: {
            text: 'Zuletzt aktualisiert am',
            readOnly: true,
        },
    }

    export const Items = mapRecordToItems(Record);
}
