export async function sleep(time: number) {
  const p = new Promise((res) => {
    setTimeout(() => {
      res(true);
    }, time);
  });
  await p;
}

export async function execWithMinDuration<T>(promise: Promise<T>, time: number) {
  const resp = await Promise.all([promise, sleep(time)]);
  return resp[0];
}
