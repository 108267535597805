<template>
  <div v-if="employee">
    <v-divider/>
    <v-skeleton-loader v-if="loading" type="list-item-avatar-two-line"/>
    <v-list-group v-else-if="employee.availabilities" active-class="blue-grey lighten-5 info--text" color="inherit">
      <template v-slot:activator>
        <v-list-item-icon>
          <v-icon>mdi-clock-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <overflow-text :text="listItemTitle"/>
          </v-list-item-title>
          <v-list-item-subtitle v-if="availableDayStrings.length > 0">
            {{ availableDayStrings.join(', ') }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else>
            Aktuell nicht verfügbar
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <div class="pa-2 pt-4">
        <v-row dense justify="center">
          <v-col cols="6">
            <v-select v-model="labelSelection" :items="AvailabilityLabelsVue" color="info" dense
                      hide-details
                      item-color="info" label="Filter wählen"
                      multiple outlined rounded/>
          </v-col>
          <v-col v-if="!disableRealTimeAvailabilities" cols="6">
            <v-tooltip nudge-left="60" right>
              <template v-slot:activator="{on}">
                <span v-on="on">
                  <v-checkbox v-model="useRealTimeAvailabilities" color="info"
                              dense hide-details label="Terminabgleich"/>
                </span>
              </template>
              Verfügbarkeiten werden mit Kursen,<br/>
              Schulungen, etc. abgeglichen
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col v-for="(item, i) in availabilityGroupItems" :key="i" class="text-center body-2 pt-2" cols="6"
                 md="4">
            <div class="font-weight-bold">{{ item.dayText }}</div>
            <div v-if="item.availabilities.length === 0" class="grey--text caption">
              Nicht verfügbar
            </div>
            <div v-for="(availability, j) in item.availabilities" :key="j" class="caption">
              {{
                getTimeStringFromMinutes(availability.timeRange.startTime)
              }}-{{ getTimeStringFromMinutes(availability.timeRange.endTime) }}
              Uhr
              <div class="mb-2">
                <v-chip v-for="(item, k) in availability.labelItems" :key="k" :color="item.color"
                        class="mx-1" x-small>
                  {{ item.text }}
                </v-chip>
              </div>
            </div>
          </v-col>
        </v-row>
        <div v-can:update-employees="{ id: employee._id, keys: ['availabilities'] }" class="text-center mt-4">
          <v-btn color="warning" outlined rounded small @click="showUpdateAvailabilitiesDialog = true">
            <v-icon small>mdi-pencil</v-icon>
            Verfügbarkeiten bearbeiten
          </v-btn>
        </div>
      </div>
      <div v-if="updateDate" class="caption text-center grey--text">
        Zuletzt aktualisiert am {{ moment(updateDate).format('DD.MM.YYYY [um] HH:mm [Uhr]') }}
      </div>
    </v-list-group>
    <v-list-item v-else-if="canReadAvailabilities && canUpdateAvailabilities" class="grey--text"
                 @click="showUpdateAvailabilitiesDialog = true">
      <v-list-item-icon>
        <v-icon>mdi-clock-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          Keine Verfügbarkeiten
        </v-list-item-title>
        <v-list-item-subtitle>
          Für diese Lehrkraft wurden keine Verfügbarkeiten hinterlegt
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <update-employee-availabilities-dialog v-model="showUpdateAvailabilitiesDialog" :employee-id="employee._id"
                                           @update:availabilities="handleAvailabilityUpdate"/>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import {Employee} from '@/interfaces/employee.interface';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import {EmployeeFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/EmployeeFields';
import UpdateEmployeeAvailabilitiesDialog
  from '@/components/employees/employeeView/profileView/availabilitiesListItem/updateEmployeeAvailabilitiesDialog.vue';
import {AvailabilityInterface, LabeledAvailabilityInterface} from '@/interfaces/availability.interface';
import {TimeRange} from '@/helpers/dateHelpers/TimeRange';
import {Weekdays} from '@/helpers/dateHelpers/Weekdays.enum';
import moment from 'moment';
import {AvailabilityLabels, AvailabilityLabelsVue} from '@/enums/AvailabilityLabels';
import OverflowText from '@/components/common/overflow-text.vue';
import {SchedulerApi} from '@/classes/api/scheduler.api.class';

moment.locale('de');

interface AvailabilityGroupItem {
  day: Weekdays;
  dayText: string;
  availabilities: Array<{
    timeRange: TimeRange;
    labelItems: Array<{ text: string; color: string }>;
  }>;
}

export default Vue.extend({
  components: {OverflowText, UpdateEmployeeAvailabilitiesDialog},
  props: {
    employee: {
      type: Object as () => Employee,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    showUpdateAvailabilitiesDialog: false,
    labelSelection: [AvailabilityLabels.FOR_COURSES],
    useRealTimeAvailabilities: true,
    disableRealTimeAvailabilities: false,
    realTimeAvailabilities: [] as LabeledAvailabilityInterface[],
  }),
  computed: {
    moment: () => moment,
    AvailabilityLabelsVue: () => AvailabilityLabelsVue,
    WeekdaysVue: () => Weekdays.Items,
    labelSelectionVue(): Array<{ text: string; value: string }> {
      return AvailabilityLabelsVue.filter(label => this.labelSelection.includes(label.value));
    },
    listItemTitle(): string {
      let title = 'Verfügbarkeiten';
      if (this.labelSelection.length > 0) {
        title += ` (${(this.labelSelectionVue as Array<{
          text: string;
          value: string
        }>).map((l) => l.text).join(', ')})`;
      }
      return title;
    },
    filteredAvailabilities(): LabeledAvailabilityInterface[] {
      const availabilities = this.useRealTimeAvailabilities ? this.realTimeAvailabilities : this.employee.availabilities || [];
      if (this.labelSelection.length === 0) {
        return availabilities;
      }
      const preFiltered = availabilities.filter((availability: LabeledAvailabilityInterface) =>
          availability.labels.some((label: AvailabilityLabels) => this.labelSelection.includes(label)),
      ) || [];
      if (!this.labelSelection.includes(AvailabilityLabels.EXCEPTIONAL)) {
        return preFiltered.filter((el) => !el.labels.includes(AvailabilityLabels.EXCEPTIONAL));
      }
      return preFiltered;
    },
    availabilityGroupItems(): AvailabilityGroupItem[] {
      const availabilityGroupItems: AvailabilityGroupItem[] = [];
      for (const day of Weekdays.Items) {
        const newItem: AvailabilityGroupItem = {
          day: day.value,
          dayText: day.text,
          availabilities: [],
        };
        for (const availability of this.filteredAvailabilities as LabeledAvailabilityInterface[]) {
          if (availability.dayOfWeek === day.value) {
            newItem.availabilities.push({
              timeRange: availability.timeRange,
              labelItems: availability.labels.map((label) => {
                const item = AvailabilityLabelsVue.find((labelVue) => labelVue.value === label);
                return item || {text: 'Unbekannt', color: 'grey'};
              }),
            });
          }
        }
        availabilityGroupItems.push(newItem);
      }
      availabilityGroupItems.sort((a, b) => a.day - b.day);
      return availabilityGroupItems;
    },
    availableDayStrings(): string[] {
      const rawWeekdayStrings = Weekdays.Items
          .filter((day) => (this.filteredAvailabilities as AvailabilityInterface[])
              .some(availability => availability.dayOfWeek === day.value))
          .map(day => day.text);
      if (rawWeekdayStrings.length >= 4) {
        return rawWeekdayStrings.map((day) => moment(day, 'dddd').format('dd'));
      } else {
        return rawWeekdayStrings.map((day) => moment(day, 'dddd').format('dddd[s]'));
      }
    },
    canReadAvailabilities(): boolean {
      if (!this.employee) {
        return false;
      }
      return this.$$getCrudEntity(CrudEntityTypes.EMPLOYEE, this.employee._id).canRead(EmployeeFields.AVAILABILITIES);
    },
    canUpdateAvailabilities(): boolean {
      if (!this.employee) {
        return false;
      }
      return this.$$getCrudEntity(CrudEntityTypes.EMPLOYEE, this.employee._id).canUpdate(EmployeeFields.AVAILABILITIES);
    },
    updateDate(): Date | undefined {
      if (!this.employee?.availabilities) {
        return undefined;
      }
      const itemsWithTimestamp = this.employee.availabilities.filter((av) => !!av.updatedAt);
      if (itemsWithTimestamp.length === 0) {
        return undefined;
      }
      return new Date(Math.max(...itemsWithTimestamp.map((av) => new Date(av.updatedAt as Date).valueOf())));
    },
  },
  methods: {
    getTimeStringFromMinutes(minutes: number) {
      return moment().startOf('day').add(minutes, 'minutes').format('HH:mm');
    },
    handleAvailabilityUpdate(availabilities: AvailabilityInterface[] | null) {
      this.$set(this.employee, 'availabilities', availabilities);
      this.initialize();
    },
    async initialize() {
      const entity = this.$$getCrudEntity(CrudEntityTypes.EMPLOYEE, this.employee._id);
      if (!entity.canRead(EmployeeFields.AVAILABILITIES)) {
        this.useRealTimeAvailabilities = false;
        this.disableRealTimeAvailabilities = true;
        return;
      }
      this.loading = true;
      try {
        const resp = await SchedulerApi.getAvailabilities({
          type: CrudEntityTypes.EMPLOYEE,
          id: this.employee._id,
        });
        this.realTimeAvailabilities = resp.availabilities;
      } catch (e) {
        this.$$showSnackbar('Fehler beim Laden der Echtzeitverfügbarkeiten - verwende statische Werte', 'error', e);
        this.useRealTimeAvailabilities = false;
        this.disableRealTimeAvailabilities = true;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.initialize();
  },
});
</script>
