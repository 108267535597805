import {mapRecordToItems} from "@/helpers/mapRecordToItems.helper";

export enum SalaryBasis {
    PER_MONTH,
    PER_HOUR,
    PER_COURSE,
    PER_STUDENT,
    PER_STUDENT_PER_MONTH,
    PER_COURSE_PER_MONTH,
}

export namespace SalaryBasis {
    type Data = { text: string };

    export const Record: Record<SalaryBasis, Data> = {
        [SalaryBasis.PER_MONTH]: { text: 'Je Monat' },
        [SalaryBasis.PER_HOUR]: { text: 'Je Stunde' },
        [SalaryBasis.PER_COURSE]: { text: 'Je Kurs' },
        [SalaryBasis.PER_STUDENT]: { text: 'Je Schüler:in je Kurs' },
        [SalaryBasis.PER_STUDENT_PER_MONTH]: { text: 'Je Schüler:in je Monat' },
        [SalaryBasis.PER_COURSE_PER_MONTH]: { text: 'Je Kurs je Monat' },
    };

    export const Items = mapRecordToItems(Record);
}
