<template>
  <v-dialog :fullscreen="fullscreen" :persistent="loading" :value="value" max-width="1600"
            @input="reset">
    <v-card>
      <v-container class="pa-0" fluid>
        <v-row no-gutters>
          <v-col v-if="$vuetify.breakpoint.mdAndUp || !hasSelection" cols="12" md="4">
            <v-card-title>
              Nachrichten
              <v-spacer/>
              <v-btn class="mb-n1" color="info" icon @click="showCreateConversationDialog = true">
                <v-icon>
                  mdi-message-text
                </v-icon>
              </v-btn>
              <v-btn v-if="$vuetify.breakpoint.smAndDown" class="ml-2 mb-n1" icon @click="reset">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider/>
            <v-skeleton-loader v-if="loading" type="list-item-two-line@6"/>
            <v-list v-else style="display: flex; flex-direction: column; height: 80vh; overflow-y: scroll">
              <v-slide-y-transition>
                <div v-if="conversations.length > 0" class="pa-3">
                  <v-text-field v-model="conversationSearch" color="info" dense hide-details label="Suche" outlined
                                rounded/>
                </div>
              </v-slide-y-transition>
              <v-list-item-group v-model="selected">
                <conversation-list-item v-for="(item, i) in filteredConversations" :key="i" :conversation="item"/>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-divider style="z-index: 1" vertical/>
          <v-col v-if="$vuetify.breakpoint.mdAndUp || hasSelection" cols="12" md="8">
            <div style="display: flex; flex-direction: column; height: 90vh">
              <div class="flex-grow-0 flex-shrink-0">
                <v-card-title>
                  <v-btn v-if="$vuetify.breakpoint.smAndDown" class="mb-n1" icon @click="selected = -1">
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                  <v-slide-x-transition hide-on-leave>
                    <conversation-title-bar v-if="filteredConversations[selected]" key="has-selection"
                                            :conversation="filteredConversations[selected]"/>
                    <div v-else key="unselected">
                      Konversationen
                    </div>
                  </v-slide-x-transition>
                  <v-spacer/>
                  <v-btn v-if="showFullScreenButton" class="mb-n1" icon @click="fullscreen = !fullscreen">
                    <v-icon>mdi-fullscreen</v-icon>
                  </v-btn>
                  <v-btn class="mb-n1" icon @click="reset">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-divider/>
              </div>
              <div style="position: absolute; bottom: 400px; right: 20px; z-index: 5">
                <v-scale-transition>
                  <v-btn v-if="!isContainerAtBottom && filteredConversations[selected]" color="info" fab
                         x-small @click="scrollToBottom(true)">
                    <v-icon>mdi-arrow-down</v-icon>
                  </v-btn>
                </v-scale-transition>
              </div>
              <div ref="messageContainer" class="flex-shrink-1 flex-grow-1" style="overflow-y: scroll">
                <div v-if="selected >= 0">
                  <message-container :conversation="filteredConversations[selected]"
                                     :is-at-bottom.sync="isContainerAtBottom" :loading="messageLoading"/>
                </div>
                <v-container v-else class="grey lighten-5 fill-height">
                  <v-row align-content="center" class="fill-height">
                    <v-col class="text-center grey--text" cols="12">
                      Keine Konversation ausgewählt
                    </v-col>
                  </v-row>
                </v-container>
              </div>
              <div :class="{ 'grey lighten-5': !filteredConversations[selected] }" class="flex-shrink-1">
                <v-slide-y-reverse-transition>
                  <messaging-input v-if="filteredConversations[selected]" :accounts="accounts"
                                   :conversation="filteredConversations[selected]"/>
                </v-slide-y-reverse-transition>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <create-conversation-dialog v-model="showCreateConversationDialog" @create="handleAddConversation"/>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import ConversationListItem from '@/components/app/messagingComponents/conversationListItem.vue';
import MessageContainer from '@/components/app/messagingComponents/messageContainer.vue';
import {MessengerApi} from '@/classes/api/messenger.api';
import {GetConversationsResponseDto} from '@/classes/dto/messenger/response/messages/GetConversations.response.dto';
import {isPopulated, retrieveObjectId} from '@/helpers/isObjectId.helper';
import mongoose, {LeanDocument} from 'mongoose';
import ConversationTitleBar from '@/components/app/messagingComponents/conversationTitleBar.vue';
import MessagingInput from '@/components/app/messagingComponents/messagingInput.vue';
import CreateConversationDialog from '@/components/app/messagingComponents/createMessagingConversationDialog.vue';
import {MessengerAccountDocument} from '@/documentTypes/messenger/MessengerAccount.document';
import {MessengerEventBus} from '@/busses/MessengerEventBus';
import {MessengerEvents} from '@/enums/notifier/serviceEvents/MessengerEvents';
import {MessengerTypesEnum} from '@/enums/messenger/MessengerTypes.enum';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import {MessengerMailDocument, MessengerWhatsAppMessageDocument} from '@/documentTypes/messenger/messages';

type Conversation = GetConversationsResponseDto['conversations'][0];

export default Vue.extend({
  components: {CreateConversationDialog, MessagingInput, ConversationTitleBar, MessageContainer, ConversationListItem},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    messageLoading: false,
    messageTitle: '',
    selected: 0,
    innerFullscreen: false,
    conversations: [] as Conversation[],
    maxPage: 1,
    isContainerAtBottom: false,
    showCreateConversationDialog: false,
    accounts: [] as Array<LeanDocument<MessengerAccountDocument>>,
    phoneNumbers: [] as Array<string>,
    emailAddresses: [] as Array<string>,

    conversationSearch: '',
  }),
  computed: {
    hasSelection(): boolean {
      // noinspection SuspiciousTypeOfGuard
      return typeof this.selected === 'number' && this.selected >= 0;
    },
    filteredConversations(): Conversation[] {
      return this.conversations.filter((conversation) => {
        return conversation.name.toLowerCase().includes(this.conversationSearch.toLowerCase()) ||
            conversation.mail?.toLowerCase().includes(this.conversationSearch.toLowerCase()) ||
            conversation.phoneNumber?.toLowerCase().includes(this.conversationSearch.toLowerCase());
      });
    },
    showFullScreenButton(): boolean {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    fullscreen: {
      get(): boolean {
        return this.innerFullscreen || !this.showFullScreenButton;
      },
      set(value: boolean) {
        this.innerFullscreen = value;
      },
    },
  },
  methods: {
    handleAddConversation(conversation: GetConversationsResponseDto['conversations'][0]) {
      this.conversations.unshift(conversation);
      this.selected = 0;
    },
    async getMessages() {
      const conversation = this.conversations[this.selected];
      if (!conversation) {
        return;
      }

      const lastMessages = conversation.messages.slice(-10);

      const alreadyLoaded = (lastMessages as mongoose.Types.ObjectId[]).every((el) => isPopulated(el));
      if (alreadyLoaded || lastMessages.length === 0) {
        return;
      }
      this.messageLoading = true;
      try {
        const resp = await MessengerApi.messages.find({
          filter: {
            _id: lastMessages.map((el) => retrieveObjectId(el)),
          },
          sortBy: 'sentAt',
          skipPagination: true,
        });
        for (const message of resp.messengerMessages) {
          const messageIndex = conversation.messages.findIndex((el) => retrieveObjectId(el)?.toString() === message._id.toString());
          if (messageIndex === -1) {
            continue;
          }
          this.$set(conversation.messages, messageIndex, message);
        }
      } catch (e) {
        this.$$showSnackbar('Fehler beim Laden der Nachrichten', 'error', e);
      } finally {
        this.messageLoading = false;
      }
    },
    async getConversations() {
      this.loading = true;
      try {
        const resp = await MessengerApi.messages.getConversations({
          page: 1,
          limit: 10,
        });
        this.conversations = resp.conversations;
        this.maxPage = resp.maxPage || 1;
        // await this.getMessages();
      } catch (e) {
        this.$$showSnackbar('Fehler beim Laden der Nachrichten', 'error', e);
      } finally {
        this.loading = false;
      }
    },
    async getAccounts() {
      this.loading = true;
      try {
        const options = await MessengerApi.accounts.getOptions();
        this.accounts = options.accounts;

        this.emailAddresses = options.mailAddressOptions.map((option) => option.mailAddress);
        this.phoneNumbers = options.phoneNumberOptions.map((option) => option.phoneNumber);
      } catch (e) {
        this.$$showSnackbar('Fehler beim Laden der Nachrichtenkanäle', 'error', e);
      } finally {
        this.loading = false;
      }
    },
    reset() {
      this.$emit('input', false);
    },
    scrollToBottom(smooth = false) {
      const container = this.$refs.messageContainer as HTMLElement;
      if (container) {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: smooth ? 'smooth' : 'auto',
        });
      }
    },
  },
  mounted() {
    this.getAccounts();
    this.getConversations();
    MessengerEventBus.$on(MessengerEvents.UPDATE_MESSAGE, async (data) => {
      const conversation = this.conversations.find((conversation) => {
        const messageIds = conversation.messages.map((message) => retrieveObjectId(message)?.toString());
        return messageIds.includes(data.messageId.toString());
      });
      if (!conversation) return;
      const messageIndex = conversation.messages.findIndex((message) => retrieveObjectId(message)?.toString() === data.messageId.toString());
      if (messageIndex === -1 || !isPopulated(conversation.messages[messageIndex])) return;
      switch (data.messageType) {
        case MessengerTypesEnum.MAIL: {
          const mailMessage = await MessengerApi.messages.mails.findById(data.messageId);
          this.$set(conversation.messages, messageIndex, mailMessage);
          break;
        }
        case MessengerTypesEnum.WHATS_APP: {
          const whatsAppMessage = await MessengerApi.messages.whatsApp.findById(data.messageId);
          this.$set(conversation.messages, messageIndex, whatsAppMessage);
          break;
        }
        default:
          throw new Error(`Unknown message type ${data.messageType}`);
      }
    })
    MessengerEventBus.$on(MessengerEvents.NEW_MESSAGE, async (data) => {
      const {messageType, apiMailAddress, apiPhoneNumber} = data;
      const isMailType = messageType === MessengerTypesEnum.MAIL && apiMailAddress && this.emailAddresses.includes(apiMailAddress);
      const isWhatsAppType = messageType === MessengerTypesEnum.WHATS_APP && apiPhoneNumber && this.phoneNumbers.includes(apiPhoneNumber);

      let message: LeanDocument<MessengerMailDocument | MessengerWhatsAppMessageDocument> | undefined,
          conversationIndex = -1, isOwn = false;

      if (isMailType) {
        const entity = this.$$getCrudEntity(CrudEntityTypes.MESSENGER_MAIL, data.messageId);
        if (!entity.canRead()) return;
        const mailMessage = await MessengerApi.messages.mails.findById(data.messageId);
        isOwn = mailMessage.username === mailMessage.fromMail;
        conversationIndex = this.conversations.findIndex((conversation) => {
          if (isOwn) {
            return conversation.mail === mailMessage.toMail;
          } else {
            return conversation.mail === mailMessage.fromMail;
          }
        });
        message = mailMessage;
      } else if (isWhatsAppType) {
        const entity = this.$$getCrudEntity(CrudEntityTypes.MESSENGER_WHATS_APP, data.messageId);
        if (!entity.canRead()) return;
        const whatsAppMessage = await MessengerApi.messages.whatsApp.findById(data.messageId);
        isOwn = whatsAppMessage.phoneNumber === whatsAppMessage.fromNumber;
        conversationIndex = this.conversations.findIndex((conversation) => {
          if (isOwn) {
            return conversation.phoneNumber === whatsAppMessage.toNumber;
          } else {
            return conversation.phoneNumber === whatsAppMessage.fromNumber;
          }
        });
        message = whatsAppMessage;
      } else {
        return;
      }

      if (!message) return;

      if (conversationIndex >= 0) {
        const conversation = this.conversations[conversationIndex];
        const messages = conversation.messages as any[];
        const messagesPopulated = messages.every((el) => isPopulated(el));
        const messageIndex = messages.findIndex((el) => retrieveObjectId(el) === data.messageId);
        if (messagesPopulated) {
          if (messageIndex === -1) {
            messages.push(message as any);
            this.$set(conversation, 'lastMessageType', messageType);
            this.$set(conversation, 'receivedLastMessageAt', message.sentAt);
            this.$set(conversation, 'lastMessagePreview', message.message);
          } else {
            messages[messageIndex] = message as any;
          }
        } else {
          if (messageIndex === -1) {
            messages.push(data.messageId as any);
            this.$set(conversation, 'lastMessageType', messageType);
            this.$set(conversation, 'receivedLastMessageAt', new Date());
            this.$set(conversation, 'lastMessagePreview', 'Neue Nachricht');
          }
          // Do not replace the message, because it is not populated yet
        }
        if (conversationIndex === this.selected) {
          this.$set(conversation, 'seen', true);
          this.$nextTick(() => {
            this.scrollToBottom(true);
          });
        } else {
          this.$set(conversation, 'seen', false);
        }
      } else {
        const mail = isOwn ? (message as any).toMail : (message as any).fromMail;
        const phoneNumber = isOwn ? (message as any).toNumber : (message as any).fromNumber;
        const name = isOwn ? (message as any).toName : (message as any).fromName;
        const profileId = isOwn ? (message as any).toProfileId : (message as any).fromProfileId;

        const conversation: Conversation = {
          messages: [message as any],
          mail,
          phoneNumber,
          name,
          lastMessagePreview: (message as any).message,
          seen: false,
          lastMessageType: data.messageType,
          profileId,
          receivedLastMessageAt: new Date(),
        };
        this.conversations.unshift(conversation);
      }

      if (!this.value && !isOwn) {
        const snackbarConfig = {
          text: `Neue Nachricht von ${message.fromName}`,
          color: 'info',
        }

        switch (data.messageType) {
          case MessengerTypesEnum.MAIL:
            snackbarConfig.text = `Neue E-Mail von ${message.fromName || (message as any).fromMail}`;
            snackbarConfig.color = 'outlook';
            break;
          case MessengerTypesEnum.WHATS_APP:
            snackbarConfig.text = `Neue WhatsApp-Nachricht von ${message.fromName || (message as any).fromNumber}`;
            snackbarConfig.color = 'whatsApp';
            break;
        }


        this.$$showSnackbar({
          text: snackbarConfig.text,
          timeout: 5000,
          btnColor: snackbarConfig.color,
          actions: [{
            text: 'Anzeigen',
            color: snackbarConfig.color,
            fn: () => {
              this.$emit('input', true);
              this.selected = conversationIndex;
            },
          }],
        });
      }

    });
  },
  watch: {
    selected: {
      async handler() {
        const conversation = this.conversations[this.selected];
        if (conversation) {
          this.$set(conversation, 'seen', true);
        }
        await this.getMessages();
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      },
    },
    value: {
      handler(value: boolean) {
        if (value) {
          this.getConversations().then(() => {
            this.scrollToBottom();
          });
        }
      },
    },
    conversationSearch() {
      this.selected = 0;
      this.scrollToBottom();
    },
  },
})
</script>
