import {API} from './api.class';
import {GetPayoffPreviewRequestDto} from '@/classes/dto/payoff-manager/request/GetPayoffPreview.request.dto';
import {Paycheck, PaycheckDocument} from '@/documentTypes/payoff-manager/Paycheck.document';
import {CreatePaycheckRequestDto} from '@/classes/dto/payoff-manager/request/CreatePaycheck.request.dto';
import {LeanDocument, Types} from 'mongoose';
import {FindByIdOptions} from '@/interfaces/findByIdOptions';
import {FindPaycheckRequestDto} from '@/classes/dto/payoff-manager/request/FindPaycheck.request.dto';
import {AuditPaycheckRequestDto} from '@/classes/dto/payoff-manager/request/AuditPaycheck.request.dto';
import {
    GetIsAvailableInvoiceNumberRequestDto,
} from '@/classes/dto/payoff-manager/request/GetIsAvailableInvoiceNumber.request.dto';
import {UpdatePaycheckRequestDto} from '@/classes/dto/payoff-manager/request/UpdatePaycheck.request.dto';
import {
    CreateOneTimeSpecialFeeRequestDto,
} from '@/classes/dto/payoff-manager/request/specialFees/CreateOneTimeSpecialFee.request.dto';
import {
    FindOneTimeSpecialFeeRequestDto,
} from '@/classes/dto/payoff-manager/request/specialFees/FindOneTimeSpecialFee.request.dto';
import {OneTimeSpecialFeeDocument} from '@/documentTypes/payoff-manager/OneTimeSpecialFee.document';
import {
    UpdateOneTimeSpecialFeeRequestDto,
} from '@/classes/dto/payoff-manager/request/specialFees/UpdateOneTimeSpecialFee.request.dto';

export type PaycheckPreview = Omit<Paycheck, 'createdAt' | 'updatedAt' | 'accountingRef' | 'storedFile' | 'specialFeeItems' | 'state' | 'invoiceNumber' | 'total' | 'invoiceGenerationType'>

export class PayoffManagerAPI extends API {
    static service = 'payoff-manager';

    public static specialFees = {
        create: async (data: CreateOneTimeSpecialFeeRequestDto): Promise<Types.ObjectId> => {
            return this.post('special-fees', data);
        },
        findById: async (id: Types.ObjectId, options?: FindByIdOptions): Promise<LeanDocument<OneTimeSpecialFeeDocument>> => {
            return this.get(`special-fees/${id}`, options);
        },
        find: async (data: FindOneTimeSpecialFeeRequestDto): Promise<{
            oneTimeSpecialFees: LeanDocument<OneTimeSpecialFeeDocument>[],
            maxPage: number
        }> => {
            return this.get('special-fees', data);
        },
        update: async (id: Types.ObjectId, data: UpdateOneTimeSpecialFeeRequestDto): Promise<Types.ObjectId> => {
            return this.put(`special-fees/${id}`, data);
        },
        delete: async (id: Types.ObjectId): Promise<Types.ObjectId> => {
            return this.delete(`special-fees/${id}`);
        },
    };

    static async create(data: CreatePaycheckRequestDto): Promise<Types.ObjectId> {
        return this.post('', data);
    }

    static async isAvailableInvoiceNumber(data: GetIsAvailableInvoiceNumberRequestDto): Promise<{
        isAvailable: boolean;
        createdAt?: Date;
    }> {
        return this.get('isAvailableInvoiceNumber', data);
    }

    static async findById(id: Types.ObjectId, options?: FindByIdOptions): Promise<LeanDocument<PaycheckDocument>> {
        return this.get(`findById/${id}`, options);
    }

    static async find(data: FindPaycheckRequestDto): Promise<{
        paychecks: LeanDocument<PaycheckDocument>[],
        maxPage: number
    }> {
        return this.get('', data);
    }

    static async audit(id: Types.ObjectId, data: AuditPaycheckRequestDto): Promise<Types.ObjectId> {
        return this.put(`audit/${id}`, data);
    }

    static async update(id: Types.ObjectId, data: UpdatePaycheckRequestDto): Promise<Types.ObjectId> {
        return this.put(`${id}`, data);
    }

    static async getPayoffPreview(data?: GetPayoffPreviewRequestDto): Promise<PaycheckPreview> {
        return this.get(`preview`, data);
    }
}
