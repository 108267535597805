import {mapRecordToItems} from '@/helpers/mapRecordToItems.helper';

export enum EmployeeContractFields {
    STORED_DOCUMENT = 'storedDocument',
    EMPLOYEE = 'employee',
    SPECIAL_CONDITIONS_INSTITUTIONS = 'specialConditionsInstitutions',
    SPECIAL_CONDITIONS_COURSES = 'specialConditionsCourses',
    TEMPLATE = 'template',
    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',
    START_DATE = 'startDate',

    SALARY_PER_SUBMISSION = 'salaryPerSubmission',
    SALARY_PER_ADVERTISING_EVENT = 'salaryPerAdvertisingEvent',

    SALARY_BASIS = 'salaryBasis',
    SALARY_PER_UNIT = 'salaryPerUnit',
    ADDITIONAL_SALARY_PER_DAY = 'additionalSalaryPerDay',
    ADDITIONAL_SALARY_PER_DAY_DECLARATION = 'additionalSalaryPerDayDeclaration',
    PAID_VACATION = 'paidVacation',
    PAID_BREAKS = 'paidBreaks',

    UPDATED_BY = 'updatedBy',
    CREATED_BY = 'createdBy',
}

export namespace EmployeeContractFields {
    type Data = {
        text: string;
        readOnly?: true;
        updateOnly?: true;
    }

    export const Record: Record<EmployeeContractFields, Data> = {
        [EmployeeContractFields.STORED_DOCUMENT]: {
            text: 'Vertragsdokument',
        },
        [EmployeeContractFields.EMPLOYEE]: {
            text: 'Mitarbeiter',
        },
        [EmployeeContractFields.SPECIAL_CONDITIONS_INSTITUTIONS]: {
            text: 'Besondere Bedingungen für Standorte',
        },
        [EmployeeContractFields.SPECIAL_CONDITIONS_COURSES]: {
            text: 'Besondere Bedingungen für Kurse',
        },
        [EmployeeContractFields.TEMPLATE]: {
            text: 'Verwendete Vorlage',
        },
        [EmployeeContractFields.CREATED_AT]: {
            text: 'Erzeugungsdatum',
            readOnly: true,
        },
        [EmployeeContractFields.UPDATED_AT]: {
            text: 'Letztes Aktualisierungsdatum',
            readOnly: true,
        },
        [EmployeeContractFields.START_DATE]: {
            text: 'Beginn der Gültigkeit',
        },
        [EmployeeContractFields.SALARY_PER_SUBMISSION]: {
            text: 'Vergütung je Neukunde',
        },
        [EmployeeContractFields.SALARY_PER_ADVERTISING_EVENT]: {
            text: 'Vergütung je Werbeaktion',
        },
        [EmployeeContractFields.SALARY_BASIS]: {
            text: 'Basis der Vergütung',
        },
        [EmployeeContractFields.SALARY_PER_UNIT]: {
            text: 'Vergütung pro Einheit (z.B. Stunde)',
        },
        [EmployeeContractFields.ADDITIONAL_SALARY_PER_DAY]: {
            text: 'Zusatzvergütung pro Tag',
        },
        [EmployeeContractFields.ADDITIONAL_SALARY_PER_DAY_DECLARATION]: {
            text: 'Erklärung zur Zusatzvergütung pro Tag',
        },
        [EmployeeContractFields.PAID_VACATION]: {
            text: 'Hat Anspruch auf bezahlten Urlaub',
        },
        [EmployeeContractFields.PAID_BREAKS]: {
            text: 'Hat Anspruch auf bezahlte Pausen',
        },
        [EmployeeContractFields.UPDATED_BY]: {
            text: 'Zuletzt aktualisiert von',
            readOnly: true,
        },
        [EmployeeContractFields.CREATED_BY]: {
            text: 'Erstellt von',
            readOnly: true,
        },
    }

    export const Items = mapRecordToItems(Record);
}
