import {mapRecordToItems} from '@/helpers/mapRecordToItems.helper';

export enum Weekdays {
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    SUNDAY,
}

export namespace Weekdays {
    type Data = { text: string, adverb: string };

    export const Record: Record<Weekdays, Data> = {
        [Weekdays.MONDAY]: {text: 'Montag', adverb: 'montags'},
        [Weekdays.TUESDAY]: {text: 'Dienstag', adverb: 'dienstags'},
        [Weekdays.WEDNESDAY]: {text: 'Mittwoch', adverb: 'mittwochs'},
        [Weekdays.THURSDAY]: {text: 'Donnerstag', adverb: 'donnerstags'},
        [Weekdays.FRIDAY]: {text: 'Freitag', adverb: 'freitags'},
        [Weekdays.SATURDAY]: {text: 'Samstag', adverb: 'samstags'},
        [Weekdays.SUNDAY]: {text: 'Sonntag', adverb: 'sonntags'},
    };

    export const Items = mapRecordToItems(Record);
}
