import {mapRecordToItems} from '@/helpers/mapRecordToItems.helper';

export enum SubmissionStates {
    ACTIVE,
    PENDING,
    CANCELLED,
    COMPLETED,
}

export namespace SubmissionStates {
    type Data = {
        text: string;
        icon: string;
        color: string;
    }

    export const Record: Record<SubmissionStates, Data> = {
        [SubmissionStates.ACTIVE]: {
            text: 'Aktive Anmeldung',
            icon: 'mdi-file-document-arrow-right',
            color: 'info',
        },
        [SubmissionStates.PENDING]: {
            text: 'Warteliste',
            icon: 'mdi-file-clock',
            color: 'warning',
        },
        [SubmissionStates.CANCELLED]: {
            text: 'Abgebrochen',
            icon: 'mdi-file-cancel',
            color: 'error',
        },
        [SubmissionStates.COMPLETED]: {
            text: 'Abgeschlossen',
            icon: 'mdi-file-check',
            color: 'success',
        },
    }

    export const Items = mapRecordToItems(Record);
}
