<template>
  <v-dialog
      :disabled="!responsibleId"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      :overlay="false"
      :value="value"
      max-width="1200px"
      persistent
      scrollable
      transition="dialog-transition"
      @input="reset"
  >
    <v-card>
      <v-card-title>
        <div class="warning--text">Ansprechpartner:in bearbeiten</div>
        <v-spacer/>
        <v-btn icon @click="reset">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="globalLoading">
        <v-skeleton-loader type="card-avatar, article, actions"/>
      </v-card-text>
      <div v-if="$$debug.enabled">
        <div class="caption">Update Object:</div>
        <pre>{{ updateObject }}</pre>
      </div>
      <v-card-text v-else-if="responsible" class="py-2">
        <v-form ref="form" v-model="valid" lazy-validation>
          <edit-personal-information :id="responsibleId" :crud-entity="CrudEntityTypes.RESPONSIBLE" :entity="responsible"
                                     :first-name.sync="firstName" :gender.sync="gender"
                                     :last-name.sync="lastName" :require-first-name="false"/>
          <v-row>
            <v-col>
              <date-picker v-model="birthday" v-can:readAndUpdate-responsibles="{ id: responsibleId, keys: ['birthday'] }" birthday-mode color="info"
                           dense
                           prepend-icon="mdi-cake-variant"/>
            </v-col>
            <v-col>
              <job-type-autocomplete v-model="jobType" v-can:read-jobTypes v-can:readAndUpdate-responsibles="{id: responsibleId, keys: ['jobType']}" color="warning" dense label="Jobposition wählen"
                                     outlined
                                     rounded/>
            </v-col>
          </v-row>
          <edit-contact-information v-model="contactInformation" v-can:readAndUpdate-responsibles="{ id: responsibleId, keys: ['contactInformation'] }"
                                    :contact-information="responsible.contactInformation"
                                    is-business-contact/>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="reset">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="globalLoading || !valid" :loading="loading" color="warning" @click="updateResponsible">
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import {GenderTypes} from '@/enums/GenderTypes.enum';
import Vue from 'vue';
import {Form} from '@/interfaces/Form.interface';

import DatePicker from '@/components/common/datePicker.vue';
import JobTypeAutocomplete from '@/components/common/search-fields/jobTypeAutocomplete.vue';
import {Types} from 'mongoose';
import {ResponsibleHandlerAPI} from '@/classes/api/responsible-handler.api.class';
import EditPersonalInformation from '@/components/common/edit/editPersonalInformation.vue';
import {Responsible} from '@/interfaces/responsible.interface';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import EditContactInformation from '@/components/common/edit/editContactInformation.vue';
import {JobType} from '@/interfaces/jobType.interface';
import {ContactInformation} from '@/interfaces/contactInformation.interface';
import {compareForUpdate, UpdateObject, UpdateType} from '@/helpers/compareForUpdate';
import {UpdateResponsibleRequestDTO} from '@/classes/dto/responsible-handler/request/UpdateResponsible.request.dto';
import {retrieveObjectId} from '@/helpers/isObjectId.helper';
import {EventBus} from '@/busses/EventBus';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    responsibleId: {
      type: Types.ObjectId,
      required: true,
    },
  },
  components: {
    EditPersonalInformation,
    JobTypeAutocomplete,
    DatePicker,
    EditContactInformation,
  },
  data: () => ({
    valid: true,
    responsible: null as null | Responsible,
    loading: false,
    globalLoading: false,
    // responsible props
    // hasLogin: false, @ToDo login implementation
    // schoolType: undefined, @ToDo remove schoolType
    firstName: undefined as UpdateType<string>,
    lastName: undefined as UpdateType<string>,
    jobType: undefined as undefined | Types.ObjectId | JobType,
    birthday: undefined as undefined | Date,
    contactInformation: undefined as UpdateType<ContactInformation>,
    gender: undefined as UpdateType<GenderTypes>,
  }),
  computed: {
    CrudEntityTypes: () => CrudEntityTypes,
    updateObject(): UpdateObject<Responsible> {
      if (!this.responsible) {
        return {};
      }
      return {
        gender: this.gender,
        firstName: this.firstName,
        lastName: this.lastName,
        jobType: compareForUpdate(this.jobType, retrieveObjectId(this.responsible?.jobType)),
        birthday: compareForUpdate(this.birthday, this.responsible?.birthday),
        contactInformation: this.contactInformation,
      }
    },
  },
  methods: {
    async getResponsible() {
      try {
        this.globalLoading = true;
        this.responsible = await ResponsibleHandlerAPI.findById(this.responsibleId);
        // @ToDo hasLogin not implemented yet
        // this.hasLogin = this.responsible.hasLogin;
        this.jobType = retrieveObjectId(this.responsible.jobType);
        this.birthday = this.responsible.birthday;
      } catch (e) {
        this.reset();
      } finally {
        this.globalLoading = false;
      }
    },
    async updateResponsible() {
      const form = this.$refs.form as Form;
      if (form && form.validate()) {
        try {
          this.loading = true;
          await ResponsibleHandlerAPI.update(this.responsibleId, this.updateObject as unknown as UpdateResponsibleRequestDTO);
          this.$$showSnackbar(`Ansprechpartner:in wurde erfolgreich aktualisiert`, 'success');
          this.$emit('update:institution');
          EventBus.$emit('update:responsible');
          this.reset();
        } catch (e) {
          this.$$showSnackbar(`Bei der Aktualisierung des Standorts ist ein Fehler aufgetreten`, 'error', e);
        } finally {
          this.loading = false;
        }
      }
    },
    reset() {
      this.responsible = null;
      const form = this.$refs.form as Form;
      if (form) {
        form.reset();
      }
      this.$emit('input', false);
    },
  },
  mounted() {
    this.getResponsible();
  },
  watch: {
    value: {
      immediate: true,
      handler(v: boolean) {
        if (v) {
          this.getResponsible();
        }
      },
    },
  },
});
</script>
