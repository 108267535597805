// inside src/mixins.ts
import Vue from 'vue';
import Component from 'vue-class-component';
import {StoreActionButtonAction} from '@/interfaces/clientOnly/storeActionButtonAction.interface';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import {Types} from 'mongoose';
import {ShareAccessDialogDataInterface} from '@/interfaces/clientOnly/shareAccessDialogData.interface';
import CrudAccessManager from '@/classes/clientOnly/permissionTreeResources/classes/CrudAccessManager';
import {CrudManagerDirective} from '@/classes/clientOnly/CrudManagerDirective';
import {SnackbarAction} from '@/interfaces/clientOnly/snackbarAction';
import {SingleAccessEntityTypes} from '@/classes/clientOnly/permissionTreeResources/classes/CrudAccessEntity';
import {LoginTypes} from '@/enums/LoginTypes.enum';
import store from '@/store';
import {User} from '@/interfaces/user.interface';
import {isAxiosError} from 'axios';
import CookieManager from '@/classes/clientOnly/CookieManager.class';
import {retrieveObjectId} from '@/helpers/isObjectId.helper';

@Component
export default class GlobalMixins extends Vue {
    get $$crudAccessManager(): CrudAccessManager {
        return this.$store.state.crudAccessManager;
    }

    get $$debug() {
        return {
            get enabled(): boolean {
                return localStorage.getItem('debug_enabled') === 'true';
            },
            toggle: () => {
                const state = localStorage.getItem('debug_enabled') === 'true';
                localStorage.setItem('debug_enabled', (!state).toString());
                this.$$showSnackbar(`Debug mode ${!state ? 'enabled' : 'disabled'}`, 'info');
            },
        };
    }

    get $$user() {
        return {
            get type(): LoginTypes | null {
                return store.state.userType;
            },
            get isTeacher(): boolean {
                return store.state.userType === LoginTypes.EMPLOYEE
                    && (!!store.state.user?.isTeacher || (!store.state.user?.isEmployee && !store.state.user?.isTeacher));
            },
            get isEmployee(): boolean {
                return store.state.userType === LoginTypes.EMPLOYEE && !!store.state.user?.isEmployee;
            },
            get isStudent(): boolean {
                return store.state.userType === LoginTypes.STUDENT;
            },
            get isParent(): boolean {
                return store.state.userType === LoginTypes.PARENT;
            },
            get meta(): User | null {
                return store.state.user;
            },
            get metaId(): Types.ObjectId | null {
                return store.state.user ? retrieveObjectId(store.state.user) : null;
            },
            get profileLink(): string | null {
                if (!store.state.userType || !store.state.user) {
                    return null;
                }
                switch (store.state.userType) {
                    case LoginTypes.EMPLOYEE:
                        return `/employees/${store.state.user._id}`;
                    case LoginTypes.STUDENT:
                        return `/students/${store.state.user._id}`;
                    case LoginTypes.PARENT:
                        return `/parents/${store.state.user._id}`;
                    default:
                        return null;
                }
            },
        };
    }

    get $$contextActions() {
        return {
            get: () => {
                return this.$store.state.actionButton.actions;
            },
            set: (...actions: StoreActionButtonAction[]) => {
                const accessibleActions = actions.filter((el) => deepValidateButtonAccess(el.access));
                if (accessibleActions.length > 0) {
                    this.$store.commit('setActions', accessibleActions);
                } else {
                    this.$store.commit('hideActions', {clearActions: true});
                }
            },
            add: (...actions: StoreActionButtonAction[]) => {
                actions = actions.filter((el) => !el.key || !this.$store.state.actionButton.actions.some((action: StoreActionButtonAction) => action.key === el.key));
                const accessibleActions = actions.filter((el) => deepValidateButtonAccess(el.access));
                if (accessibleActions.length > 0) {
                    this.$store.commit('setActions', [...this.$store.state.actionButton.actions, ...accessibleActions]);
                }
            },
            clearKey: (key: string) => {
                const filteredActions = this.$store.state.actionButton.actions.filter((action: StoreActionButtonAction) => action.key !== key);
                if (filteredActions.length > 0) {
                    this.$store.commit('setActions', filteredActions);
                } else {
                    this.$store.commit('hideActions', {clearActions: true});
                }
            },
            clear: () => {
                this.$store.commit('hideActions', {clearActions: true});
            },
        };
    }

    get $$shareAccessDialog() {
        return {
            show: (entityType: SingleAccessEntityTypes, id: Types.ObjectId, dialogTitle?: string) => {
                this.$store.commit('setShareAccessDialogInfo', {
                    show: true,
                    entityType,
                    id,
                    dialogTitle,
                } as ShareAccessDialogDataInterface);
            },
            hide: () => {
                this.$store.commit('setShareAccessDialogInfo', {
                    ...this.$store.state.shareAccessDialogInfo,
                    show: false,
                } as ShareAccessDialogDataInterface);
            },
        };
    }

    $$showSnackbar(data: string, color?: string): void;
    $$showSnackbar(data: string, color: 'error', error: any): void;
    $$showSnackbar(data: { text: string; btnColor?: string; timeout?: number; actions?: SnackbarAction[] }): void;
    $$showSnackbar(data: {
        text: string;
        btnColor?: string;
        timeout?: number;
        actions?: SnackbarAction[]
    } | string, color?: string, error?: any) {
        if (typeof data === 'string') {
            this.$store.commit('showSnackbar', {
                text: data,
                btnColor: color,
                info: color === 'error' && !!error
                    ? isAxiosError(error)
                        ? `${error.status || error.code || ''} ${error.message}`.trim()
                        : error
                    : undefined,
            });
        } else {
            this.$store.commit('showSnackbar', data);
        }
    }

    async $$waitForInterrupt(text: string, timeout?: number): Promise<boolean> {
        return new Promise((resolve) => {
            this.$store.commit('hideSnackbar');
            this.$store.commit('showInterruptSnackbar', {
                text,
                timeout,
            });
            this.$store.watch((state) => state.interruptSnackbar.show, (show: boolean) => {
                if (!show) {
                    resolve(this.$store.state.interruptTriggered);
                }
            });
        });
    }

    $$getCrudEntity(type: CrudEntityTypes, id: Types.ObjectId) {
        return this.$store.state.crudAccessManager.entity(type, id);
    }

    get $$cookieManager(): CookieManager {
        return this.$store.state.cookieManager;
    }
}

function deepValidateButtonAccess(access: StoreActionButtonAction['access']): boolean {
    for (const accessObject of access) {
        const cleanAccessObject = accessObject as any;
        if (cleanAccessObject.$or) {
            const atLeastOneTrue = (cleanAccessObject.$or as StoreActionButtonAction['access'])
                .some((el) => deepValidateButtonAccess([el]));
            if (!atLeastOneTrue) {
                return false;
            }
        } else {
            const response = CrudManagerDirective.checkAbility({
                action: cleanAccessObject.action,
                entity: cleanAccessObject.entity,
                id: cleanAccessObject.id,
                keys: cleanAccessObject.accessKeys,
            });
            if (!response) {
                return false;
            }
        }
    }
    return true;
}
