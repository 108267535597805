import {sleep} from '@/helpers/sleep.helper';

export {}

declare global {
    interface Promise<T> {
        /**
         * Set the minimum time for the promise to resolve
         * @param time The minimum time in milliseconds - default is 500
         */
        minTime(time?: number): Promise<T>;
    }
}

if (!Promise.prototype.minTime) {
    Promise.prototype.minTime = async function<T>(this: Promise<T>, time = 500): Promise<T> {
        const resp = await Promise.all([this, sleep(time)]);
        return resp[0];
    }
}
