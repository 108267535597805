<template>
  <v-dialog :value="$store.state.shareAccessDialogInfo.show" max-width="800" scrollable @input="reset">
    <v-card>
      <v-card-title>
        <v-icon class="mr-2">mdi-shield-lock-open</v-icon>
        <span class="info--text">
          {{ title }}
        </span>
        <v-spacer/>
        <v-btn icon @click="reset">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-autocomplete v-model="userId" :items="userItems" :loading="loadingUserItems"
                          :no-data-text="userSearchNoData" :rules="[RuleFactory.required()]"
                          :search-input.sync="userItemSearchInput" cache-items color="info" dense
                          item-color="info" label="Benutzer" outlined
                          prepend-icon="mdi-account-circle" rounded @input="getUserAccess"/>

          <v-autocomplete v-model="readSelection" :disabled="!userSelectionReady || grantFullReadAccess"
                          :items="readItems"
                          color="info" dense item-color="info"
                          label="Lesezugriff" multiple outlined prepend-icon="mdi-magnify">
            <template v-slot:selection="data">
              <v-card class="mb-1 mt-2 mr-1" tile>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon small @click="readSelection = readSelection.filter((v) => v !== data.item.value)">
                      <v-icon small>mdi-close-circle</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-card>
            </template>
          </v-autocomplete>
          <v-checkbox v-model="grantFullReadAccess" :disabled="!userSelectionReady" class="mt-n3" color="info" dense>
            <template v-slot:label>
              <div class="ml-4 caption">
                Vollen Lesezugriff gewähren
              </div>
            </template>
          </v-checkbox>

          <v-autocomplete v-model="writeSelection" :disabled="!userSelectionReady || grantFullWriteAccess"
                          :items="writeItems"
                          color="info" dense item-color="info" label="Schreibzugriff"
                          multiple outlined
                          prepend-icon="mdi-pencil">
            <template v-slot:selection="data">
              <v-card class="mb-1 mt-2 mr-1" tile>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon small @click="writeSelection = writeSelection.filter((v) => v !== data.item.value)">
                      <v-icon small>mdi-close-circle</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-card>
            </template>
          </v-autocomplete>
          <v-checkbox v-model="grantFullWriteAccess" :disabled="!userSelectionReady" class="mt-n3" color="info" dense>
            <template v-slot:label>
              <div class="ml-4 caption">
                Vollen Schreibzugriff gewähren
              </div>
            </template>
          </v-checkbox>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="reset">
          Abbrechen
        </v-btn>
        <v-spacer/>
        <v-btn :disabled="!valid" :loading="loading" color="info" @click="preSave">
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
    <password-dialog v-model="showPasswordDialog" @commit="save"/>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import {sleep} from '@/helpers/sleep.helper';
import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {Form} from '@/interfaces/Form.interface';
import PasswordDialog from '@/components/common/passwordDialog.vue';
import {PermissionerApi} from '@/classes/api/permissioner.api.class';
import {CrudActionTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes';
import {UserLoginHandlerApi} from '@/classes/api/user-login-handler.api';
import CrudAccessManager from '@/classes/clientOnly/permissionTreeResources/classes/CrudAccessManager';
import mongoose from 'mongoose';
import {LoginUserFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/LoginUserFields';

export default Vue.extend({
  components: {PasswordDialog},
  data: () => ({
    userItemSearchInput: '',
    userItems: [] as Array<{ text: string; value: mongoose.Types.ObjectId }>,
    userId: null as mongoose.Types.ObjectId | null,
    loadingUserItems: false,
    throttleCounter: 0,
    readSelection: [] as string[],
    writeSelection: [] as string[],
    grantFullReadAccess: false,
    grantFullWriteAccess: false,
    showPasswordDialog: false,
    valid: false,
    loading: false,
    userSelectionReady: false,
    userAccessUnavailable: false,
  }),
  computed: {
    RuleFactory: () => RuleFactory,
    title(): string {
      return this.$store.state.shareAccessDialogInfo.dialogTitle || 'Entität freigeben';
    },
    userSearchNoData(): string {
      if (this.loadingUserItems) {
        return 'Suche...';
      } else {
        return 'Kein Benutzer gefunden';
      }
    },
    fieldDictionary(): FieldDictionary<any> {
      const entityType = this.$store.state.shareAccessDialogInfo.entityType as CrudEntityTypes | undefined;
      if (!entityType) {
        return [];
      }
      return CrudEntityTypes.Record[entityType]?.fields || CrudEntityTypes.Record[entityType]?.fieldSpace?.Items || [];
    },
    readItems(): FieldDictionary<any> {
      return this.fieldDictionary.filter((item) => !item.updateOnly);
    },
    writeItems(): FieldDictionary<any> {
      return this.fieldDictionary.filter((item) => !item.readOnly);
    },
  },
  methods: {
    async findUserItems() {
      if (!this.userItemSearchInput) {
        return;
      }
      this.loadingUserItems = true;
      this.throttleCounter++;
      await sleep(500);
      this.throttleCounter--;
      if (this.throttleCounter === 0) {
        try {
          if (this.userItemSearchInput) {
            const resp = await UserLoginHandlerApi.find({
              filter: {$search: this.userItemSearchInput},
              populate: {
                meta: {
                  fields: ['firstName', 'lastName', 'customId'],
                },
              },
            });
            this.userItems = resp.loginUsers.map((loginUser) => ({
              text: `${(loginUser.meta as any).firstName} ${(loginUser.meta as any).lastName} - ${(loginUser.meta as any).customId}`,
              value: loginUser._id as unknown as mongoose.Types.ObjectId,
            }));
          }
        } finally {
          this.loadingUserItems = false;
        }
      }
    },
    preSave() {
      const form = this.$refs.form as Form;
      if (form.validate()) {
        this.showPasswordDialog = true;
      }
    },
    async save(_pass: string) {
      const form = this.$refs.form as Form;
      if (form.validate()) {
        this.loading = true;
        try {
          const loginUser = await UserLoginHandlerApi.findById(this.userId as mongoose.Types.ObjectId);
          await PermissionerApi.grantAccess('user', loginUser._id, {
            entityId: this.$store.state.shareAccessDialogInfo.id,
            entityType: this.$store.state.shareAccessDialogInfo.entityType,
            action: CrudActionTypes.READ,
            fields: this.grantFullReadAccess ? ['fullAccess'] : this.readSelection.filter((el) => el !== '_id'),
            _pass,
          });
          await PermissionerApi.grantAccess('user', loginUser._id, {
            entityId: this.$store.state.shareAccessDialogInfo.id,
            entityType: this.$store.state.shareAccessDialogInfo.entityType,
            action: CrudActionTypes.UPDATE,
            fields: this.grantFullWriteAccess ? ['fullAccess'] : this.writeSelection.filter((el) => el !== '_id'),
            _pass,
          });
          this.reset();
          this.$$showSnackbar({
            text: 'Freigabe erfolgreich',
            btnColor: 'info',
          });
        } finally {
          this.loading = false;
        }
      }
    },
    async getUserAccess() {
      if (!this.$$crudAccessManager.canReadAllProvided(CrudEntityTypes.LOGIN_USER, LoginUserFields.META)) {
        return;
      }
      if (this.userId) {
        try {
          const user = await UserLoginHandlerApi.findByMeta(this.userId);
          const crud = await PermissionerApi.getCrudById('user', user._id);
          const crudAccessManager = new CrudAccessManager(crud);
          const entity = crudAccessManager.entity(this.$store.state.shareAccessDialogInfo.entityType, this.$store.state.shareAccessDialogInfo.id);
          if (entity.hasFullReadAccess) {
            this.grantFullReadAccess = true;
          } else {
            for (const key in entity.readFields) {
              if (entity.readFields.hasOwnProperty(key) && entity.readFields[key]) {
                this.readSelection.push(key);
              }
            }
          }
          if (entity.hasFullUpdateAccess) {
            this.grantFullWriteAccess = true;
          } else {
            for (const key in entity.updateFields) {
              if (entity.updateFields.hasOwnProperty(key) && entity.updateFields[key]) {
                this.writeSelection.push(key);
              }
            }
          }
        } catch (e) {
          this.userAccessUnavailable = true;
        } finally {
          this.userSelectionReady = true;
        }
      }
    },
    reset() {
      const form = this.$refs.form as Form;
      form.reset();
      this.$$shareAccessDialog.hide();
    },
  },
  watch: {
    userItemSearchInput() {
      this.findUserItems();
    },
    grantFullReadAccess() {
      this.readSelection = [];
    },
    grantFullWriteAccess() {
      this.writeSelection = [];
    },
  },
});
</script>
