<template>
  <span>
    <v-text-field v-model="stringValue" :prefix="prefix" clearable :rules="[rulesFunction]"
                  v-bind="{...$props}" @blur="formatStringValue" @input="onInput"/>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import {InputStyleOptions} from '@/constants/InputStyleOptions.constant';
import {Rule} from '@/helpers/ruleFactory.helper';
import {sleep} from '@/helpers/sleep.helper';

export default Vue.extend({
  props: {
    ...InputStyleOptions.TextField,
    rules: {
      default: () => [],
      type: Array as () => Rule[],
    },
    value: {
      type: Number,
      default: undefined,
    },
    prefix: {
      type: String,
      default: '€',
    },
  },
  data: () => ({
    stringValue: '', // Stores the raw input value
    throttleCounter: 0,
  }),
  computed: {
    numValue(): number | undefined {
      if (!this.stringValue) {
        return undefined;
      }
      const v = parseFloat(this.stringValue.replace(/\./g, '').replace(',', '.'));
      return isNaN(v) ? undefined : parseFloat(v.toFixed(2));
    },
  },
  watch: {
    // Updates the input field whenever the value prop changes
    value: {
      immediate: true,
      handler(newValue: number | undefined) {
        if (newValue === this.numValue) {
          return;
        }
        this.stringValue = typeof newValue === 'number' ? newValue.toLocaleString('de-DE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) : '';
      },
    },
  },
  methods: {
    rulesFunction(val: string) {
      if (val) {
        if (isNaN(parseFloat(val))) {
          return 'Der Wert muss eine Zahl sein';
        }
      }
      for (const rule of this.rules) {
        const resp = rule(this.numValue);
        if (typeof resp === 'string') {
          return resp;
        }
      }
      return true;
    },
    async onInput() {
      // Handle clearable behavior (null input)
      if (!this.stringValue) {
        this.$emit('input', undefined);
        return;
      }
      if (isNaN(parseFloat(this.stringValue))) {
        this.$emit('input', undefined);
        return;
      }

      this.$emit('input', this.numValue);

      this.throttleCounter++;
      await sleep(3000); // Throttle input to prevent too many updates
      this.throttleCounter--;
      if (this.throttleCounter > 0) return;
      this.formatStringValue();
    },
    formatStringValue() {
      this.stringValue = this.numValue?.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) ?? '';
    },
  },
});
</script>
