import { Types } from 'mongoose';
import {isMongoId} from "class-validator";

/**
 * Returns if given data is a valid ObjectId of mongoose.
 * Allows strings and numbers as valid input.
 * @param data The data to validate
 * @returns Is data valid ObjectId?
 * @author Joshua Seipel
 */
export function isObjectId(data: any): data is Types.ObjectId {
  try {
    return isMongoId(data);
  } catch (error) {
    return false;
  }
}

type Populated<T> = T extends Types.ObjectId | null | undefined ? never : T & { _id: Types.ObjectId };

export function isPopulated<T>(data: T): data is Populated<T> {
  return !!data && !isObjectId(data);
}

type RetrieveObjectIdReturnType<T> = T extends undefined ? Types.ObjectId | undefined : Types.ObjectId;

export function retrieveObjectId<T>(data: T): RetrieveObjectIdReturnType<T> {
  if (data === undefined) {
    return undefined as RetrieveObjectIdReturnType<T>;
  }
  if (isPopulated(data)) {
    return data._id as RetrieveObjectIdReturnType<T>;
  }
  return data as RetrieveObjectIdReturnType<T>;
}
