import {mapRecordToItems} from '@/helpers/mapRecordToItems.helper';

export enum PaycheckFields {
    EMPLOYEE = 'employee',
    CONTRACTS = 'contracts',
    INVOICE_NUMBER = 'invoiceNumber',
    FROM = 'from',
    TO = 'to',
    ACCOUNTING_REF = 'accountingRef',
    STORED_FILE = 'storedFile',
    INVOICE_GENERATION_TYPE = 'invoiceGenerationType',
    COURSE_ITEMS = 'courseItems',
    HANDOUT_EVENT_ITEMS = 'handoutEventItems',
    SUBMISSION_ITEMS = 'submissionItems',
    BILL_ITEMS = 'billItems',
    SPECIAL_FEE_ITEMS = 'specialFeeItems',
    STATE = 'state',
    TOTAL_BEFORE_SPECIAL_FEES = 'totalBeforeSpecialFees',
    TOTAL = 'total',
    CREATED_BY = 'createdBy',
    UPDATED_BY = 'updatedBy',
    PAYMENT_PREPARED_BY = 'paymentPreparedBy',
    PAYMENT_PREPARED_AT = 'paymentPreparedAt',
    PAID_AT = 'paidAt',
    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',
}

export namespace PaycheckFields {
    type Data = {
        text: string;
        readOnly?: true;
        updateOnly?: true;
    }

    export const Record: Record<PaycheckFields, Data> = {
        [PaycheckFields.EMPLOYEE]: {
            text: 'Mitarbeiter',
        },
        [PaycheckFields.CONTRACTS]: {
            text: 'Verträge',
        },
        [PaycheckFields.INVOICE_NUMBER]: {
            text: 'Rechnungsnummer',
        },
        [PaycheckFields.FROM]: {
            text: 'Von',
        },
        [PaycheckFields.TO]: {
            text: 'Bis',
        },
        [PaycheckFields.ACCOUNTING_REF]: {
            text: 'Buchungsreferenz',
        },
        [PaycheckFields.STORED_FILE]: {
            text: 'Gespeicherte Datei',
        },
        [PaycheckFields.INVOICE_GENERATION_TYPE]: {
            text: 'Rechnungsgenerierungstyp',
        },
        [PaycheckFields.COURSE_ITEMS]: {
            text: 'Kurse',
        },
        [PaycheckFields.HANDOUT_EVENT_ITEMS]: {
            text: 'Austeilaktionen',
        },
        [PaycheckFields.SUBMISSION_ITEMS]: {
            text: 'Neukunden-Anmeldungen',
        },
        [PaycheckFields.BILL_ITEMS]: {
            text: 'Rechnungen',
        },
        [PaycheckFields.SPECIAL_FEE_ITEMS]: {
            text: 'Sonderhonorare',
        },
        [PaycheckFields.STATE]: {
            text: 'Status',
        },
        [PaycheckFields.TOTAL_BEFORE_SPECIAL_FEES]: {
            readOnly: true,
            text: 'Gesamtsumme vor Sonderhonorar',
        },
        [PaycheckFields.TOTAL]: {
            readOnly: true,
            text: 'Gesamtsumme',
        },
        [PaycheckFields.CREATED_BY]: {
            text: 'Erstellt von',
            readOnly: true,
        },
        [PaycheckFields.UPDATED_BY]: {
            text: 'Zuletzt aktualisiert von',
            readOnly: true,
        },
        [PaycheckFields.PAYMENT_PREPARED_BY]: {
            text: 'Zahlung vorbereitet von',
        },
        [PaycheckFields.PAYMENT_PREPARED_AT]: {
            text: 'Zahlung vorbereitet am',
        },
        [PaycheckFields.PAID_AT]: {
            text: 'Bezahlt am',
            readOnly: true,
        },
        [PaycheckFields.CREATED_AT]: {
            text: 'Erstellt am',
            readOnly: true,
        },
        [PaycheckFields.UPDATED_AT]: {
            text: 'Zuletzt aktualisiert am',
            readOnly: true,
        },
    }

    export const Items = mapRecordToItems(Record);
}
