<template>
  <v-menu v-model="showMenu" ref="menu" min-width="160" offset-overflow content-class="elevation-2"
          transition="slide-y-reverse-transition" :nudge-top="nudgeTop">
    <template v-slot:activator="{on}">
      <v-slide-y-reverse-transition origin="center center">
        <v-btn fab fixed right bottom v-on="on" color="blue" dark class="elevation-2"
               v-show="showButton">
          <v-scale-transition group leave-absolute origin="center center">
            <v-icon key="close" v-if="showMenu">mdi-close</v-icon>
            <v-icon key="single" v-else-if="actions.length === 1">{{ getIcon(actions[0].icon) }}</v-icon>
            <v-icon key="default" v-else>mdi-format-list-bulleted</v-icon>
          </v-scale-transition>
        </v-btn>
      </v-slide-y-reverse-transition>
    </template>
    <v-list dense class="py-0">
      <template v-for="(action, i) in actions">
        <v-list-item :key="`action-${i}`" @click="action.fn">
          <v-list-item-icon>
            <v-icon>{{ getIcon(action.icon) }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ getName(action.name) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="i !== actions.length - 1" :key="`action-divider-${i}`" />
      </template>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import Vue from 'vue';
import {StoreActionButtonAction} from '@/interfaces/clientOnly/storeActionButtonAction.interface';

export default Vue.extend({
  data: () => ({
    showMenu: false,
  }),
  computed: {
    showButton(): boolean {
      return this.$store.state.actionButton.show && this.actions.length > 0;
    },
    actions(): StoreActionButtonAction[] {
      return this.$store.state.actionButton.actions
          .filter((action: StoreActionButtonAction) => {
            if (typeof action.isVisible === 'undefined') {
              return true;
            }
            return typeof action.isVisible === 'function' ? action.isVisible() : action.isVisible
          });
    },
    nudgeTop(): string {
      const bias = 16;
      const elementHeight = 40;
      return bias + elementHeight * this.$store.state.actionButton.actions.length + 'px';
    },
  },
  methods: {
    getName(nameProp: string | (() => string)): string {
      if (typeof nameProp === 'function') {
        return nameProp();
      }
      return nameProp;
    },
    getIcon(iconProp: string | (() => string)): string {
      if (typeof iconProp === 'function') {
        return iconProp();
      }
      return iconProp;
    },
  },
});
</script>
