import {mapRecordToItems} from '@/helpers/mapRecordToItems.helper';

export enum EmployeeFields {
    GENDER = 'gender',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    BIRTHDAY = 'birthday',
    ADDRESS = 'address',
    PAYMENT = 'payment',
    TAX_INFORMATION = 'taxInformation',
    HAS_LOGIN = 'hasLogin',
    IS_ACTIVE = 'isActive',
    DEACTIVATED_AT = 'deactivatedAt',
    CONTACT_INFORMATION = 'contactInformation',
    CUSTOM_ID = 'customId',
    CUSTOM_ID_SCHEME = 'customIdScheme',
    ACCOUNTING_REF = 'accountingRef',

    SUBJECTS = 'subjects',
    /** @deprecated */
    CONTRACT = 'contract',
    EMPLOYEE_CONTRACT = 'employeeContract',
    IS_TEACHER = 'isTeacher',
    IS_EMPLOYEE = 'isEmployee',
    IS_APPLICANT = 'isApplicant',
    EXCLUDE_FROM_OPTIMIZATIONS = 'excludeFromOptimizations',
    HAS_DRIVER_LICENSE = 'hasDriverLicense',
    HAS_CAR = 'hasCar',
    MAX_DISTANCE = 'maxDistance',
    MAX_TRAVEL_TIME = 'maxTravelTime',
    PROFILE_IMG = 'profileImg',

    ASSIGNED_INSTITUTIONS = 'assignedInstitutions',
    PLANNED_INSTITUTIONS = 'plannedInstitutions',

    BLOCKED_SLOTS_CALENDAR = 'blockedSlotsCalendar',
    EXCEPTIONAL_BLOCKED_SLOTS_CALENDAR = 'exceptionalBlockedSlotsCalendar',
    AVAILABILITIES = 'availabilities',

    ABILITIES = 'abilities',
    TAGS = 'tags',

    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',

    COMPLIANCE = 'compliance',

    ACQUIRED_BY = 'acquiredBy',
    RECRUITING_PLATFORM = 'recruitingPlatform',
    APPLICATIONS = 'applications',

    SUPERVISOR = 'supervisor',
    IS_SUPERVISOR = 'isSupervisor',
    IS_DEFAULT_SUPERVISOR_FOR_STATES = 'defaultSupervisorForStates',

    DEACTIVATE_AT = 'deactivateAt',
    ACTIVATE_AT = 'activateAt',
}

export namespace EmployeeFields {
    type Data = {
        text: string;
        readOnly?: true;
        updateOnly?: true;
    }

    export const Record: Record<EmployeeFields, Data> = {
        [EmployeeFields.EMPLOYEE_CONTRACT]: {
            text: 'Vertrag',
        },
        [EmployeeFields.IS_TEACHER]: {
            text: 'Ist Lehrkraft?',
        },
        [EmployeeFields.IS_EMPLOYEE]: {
            text: 'Ist Mitarbeiter?',
        },
        [EmployeeFields.IS_APPLICANT]: {
            text: 'Ist Bewerber?',
        },
        [EmployeeFields.IS_ACTIVE]: {
            text: 'Aktiv',
        },
        [EmployeeFields.DEACTIVATED_AT]: {
            text: 'Deaktiviert am',
            readOnly: true,
        },
        [EmployeeFields.SUBJECTS]: {
            text: 'Fächer',
        },
        [EmployeeFields.CONTRACT]: {
            text: 'Vertrag (veraltet)',
        },
        [EmployeeFields.HAS_DRIVER_LICENSE]: {
            text: 'Führerschein vorhanden',
        },
        [EmployeeFields.HAS_CAR]: {
            text: 'Fahrzeug vorhanden',
        },
        [EmployeeFields.MAX_DISTANCE]: {
            text: 'Maximale Entfernung',
        },
        [EmployeeFields.PROFILE_IMG]: {
            text: 'Profilbild',
        },
        [EmployeeFields.ASSIGNED_INSTITUTIONS]: {
            text: 'Zugeteilte Standorte',
        },
        [EmployeeFields.PLANNED_INSTITUTIONS]: {
            text: 'Geplante Standorte',
        },
        [EmployeeFields.BLOCKED_SLOTS_CALENDAR]: {
            text: 'Gesperrte Zeiten',
        },
        [EmployeeFields.EXCEPTIONAL_BLOCKED_SLOTS_CALENDAR]: {
            text: 'Ausnahmen',
        },
        [EmployeeFields.AVAILABILITIES]: {
            text: 'Verfügbarkeiten',
        },
        [EmployeeFields.TAGS]: {
            text: 'Tags',
        },
        [EmployeeFields.CREATED_AT]: {
            text: 'Erstellt am',
            readOnly: true,
        },
        [EmployeeFields.UPDATED_AT]: {
            text: 'Zuletzt aktualisiert am',
            readOnly: true,
        },
        [EmployeeFields.COMPLIANCE]: {
            text: 'Datenschutz',
        },
        [EmployeeFields.ACQUIRED_BY]: {
            text: 'Akquiriert von',
        },
        [EmployeeFields.RECRUITING_PLATFORM]: {
            text: 'Recruiting-Plattform',
        },
        [EmployeeFields.APPLICATIONS]: {
            text: 'Bewerbungen',
        },
        [EmployeeFields.SUPERVISOR]: {
            text: 'Vorgesetzte:r',
        },
        [EmployeeFields.IS_SUPERVISOR]: {
            text: 'Ist Vorgesetzte:r',
        },
        [EmployeeFields.IS_DEFAULT_SUPERVISOR_FOR_STATES]: {
            text: 'Standardvorgesetzte:r für Bundesland',
        },
        [EmployeeFields.DEACTIVATE_AT]: {
            text: 'Deaktivieren am',
            readOnly: true,
        },
        [EmployeeFields.ACTIVATE_AT]: {
            text: 'Aktivieren am',
            readOnly: true,
        },
        [EmployeeFields.GENDER]: {
            text: 'Geschlecht',
        },
        [EmployeeFields.FIRST_NAME]: {
            text: 'Vorname',
        },
        [EmployeeFields.LAST_NAME]: {
            text: 'Nachname',
        },
        [EmployeeFields.BIRTHDAY]: {
            text: 'Geburtsdatum',
        },
        [EmployeeFields.ADDRESS]: {
            text: 'Adresse',
        },
        [EmployeeFields.PAYMENT]: {
            text: 'Zahlungsinformationen',
        },
        [EmployeeFields.TAX_INFORMATION]: {
            text: 'Steuerinformationen',
        },
        [EmployeeFields.HAS_LOGIN]: {
            text: 'Hat Zugang zu Vanilla',
        },
        [EmployeeFields.CONTACT_INFORMATION]: {
            text: 'Kontaktdaten',
        },
        [EmployeeFields.CUSTOM_ID]: {
            text: 'Mitarbeitendennummer',
            readOnly: true,
        },
        [EmployeeFields.CUSTOM_ID_SCHEME]: {
            text: 'Art der Mitarbeitendennummer',
            readOnly: true,
        },
        [EmployeeFields.ACCOUNTING_REF]: {
            text: 'Referenz zur Buchhaltungsschnittstelle',
            readOnly: true,
        },
        [EmployeeFields.ABILITIES]: {
            text: 'Fähigkeiten',
        },
        [EmployeeFields.EXCLUDE_FROM_OPTIMIZATIONS]: {
            text: 'Von Optimierungen ausnehmen',
        },
        [EmployeeFields.MAX_TRAVEL_TIME]: {
            text: 'Maximale Fahrtzeit',
        },
    }

    export const Items = mapRecordToItems(Record);
}
